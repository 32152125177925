import React, { FC, useCallback } from "react";
import { makeStyles, Box, Divider, Theme, Typography } from "@material-ui/core";
import { Slider, withSlidePreviewer, Panel } from "@periplus/ui-library";
import PrintButton from "./components/PrintButton";
import DownloadButton from "./components/DownloadButton";
import ShareButton from "./components/ShareButton";
import SliderPreview from "./components/SliderPreview";
import clsx from "clsx";
import { ITripDocumentV } from "graphql/tripActionDocuments/types";
import getDownloadUrl from "utils/azureBlobStorageUtils";

const SliderWithPreviewer = withSlidePreviewer(Slider);

interface TripDocumentsProps {
  className: string;
  tripDataDocuments: ITripDocumentV[];
}

const useStyles = makeStyles((theme: Theme) => ({
  documentLabel: {
    "&:not(:first-child)": {
      marginLeft: 8,
    },
  },
  sliderContainer: {
    marginTop: 22.5,
    display: "flex",
  },
  slider: {
    flexGrow: 1,
    flex: "auto",
    position: "relative",
    height: 164,
    marginLeft: 18,
    marginRight: 18,
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    minWidth: 30,
  },
  divider: {
    height: 75,
  },
  controlIcon: {
    color: "#757575",
    cursor: "pointer",
    "&:not(:first-child)": {
      marginTop: 6,
    },
  },
  slide: {
    border: `1px solid #C8C8D4`,
    borderRadius: 4,
    overflow: "hidden",
  },
  documentField: {
    color: "#C8C8D4",
    fontSize: 14,
    fontWeight: 600,
  },
}));

const TripDocuments: FC<TripDocumentsProps> = ({
  tripDataDocuments,
  className,
}) => {
  const classes = useStyles();

  const renderSlide = useCallback(
    ({ data, index, style }) => {
      const item = data[index];

      return (
        <div
          className={clsx(classes.slide)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...style,
            width: 126,
            height: 161,
          }}
          data-pp="slide"
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={item}
            alt="document page preview"
            key={item}
          />
        </div>
      );
    },
    [classes.slide]
  );

  return (
    <Panel
      className={className}
      title="Documents"
      subTitle="All documents which are attached to this Shipment along the course."
    >
      {tripDataDocuments.map((tripDataDocument) => {
        const tripDocumentPartUrl = tripDataDocument.url
          .split(".")
          .slice(0, -1)
          .join(".");
        const tripDocumentThumbnails = [] as string[];
        for (let i = 1; i <= tripDataDocument.pages_qty; i++) {
          tripDocumentThumbnails.push(
            getDownloadUrl(
              `${tripDocumentPartUrl}-${i.toString().padStart(3, "0")}.jpg`
            )
          );
        }
        return (
          <Box key={tripDataDocument.param_name}>
            <Typography className={classes.documentField}>
              {tripDataDocument.param_name}:
            </Typography>
            <Box className={classes.sliderContainer}>
              <Box className={classes.slider}>
                <SliderWithPreviewer
                  itemWidth={145}
                  itemsSpacing={0}
                  itemWidthSpacing={0}
                  containerSpacing={0}
                  edgeFriction={0.35}
                  slidesData={tripDocumentThumbnails}
                  renderPreview={SliderPreview}
                  hideContainer
                >
                  {renderSlide}
                </SliderWithPreviewer>
              </Box>
              <Divider orientation="vertical" className={classes.divider} />
              <Box className={classes.controls}>
                <DownloadButton
                  className={classes.controlIcon}
                  url={tripDataDocument.url}
                />
                <ShareButton
                  className={classes.controlIcon}
                  url={tripDataDocument.url}
                />
                <PrintButton
                  className={classes.controlIcon}
                  url={tripDataDocument.url}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Panel>
  );
};

export default TripDocuments;
