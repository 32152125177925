export const toJson = (
  object: any,
  replacer: (number | string)[] | null = null,
  space: string | number = 2
): string => {
  try {
    return JSON.stringify(object, replacer, space);
  } catch (e) {
    console.error(e);
    return object;
  }
};

export const fromJson = (
  json: string,
  reviver?: (key: string, value: any) => any
): any => {
  try {
    return JSON.parse(json, reviver);
  } catch (e) {
    console.error(e);
    return json;
  }
};
