import React, { FC, useEffect, useMemo } from "react";
import { LatLngTuple } from "leaflet"; //icon,
import {
  AttributionControl,
  MapContainer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { ITrip } from "graphql/trips";
import { antPath } from "leaflet-ant-path";

const FocusedMarker: FC<{ position: LatLngTuple }> = ({ position }) => {
  //const markerIcon = useMemo(() => icon({ iconUrl: "logo192.png" }), []);

  return (
    //icon={markerIcon}
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

const Path: FC<{
  coords: LatLngTuple[];
  focus: [number, number] | undefined;
}> = ({ coords, focus }) => {
  const map = useMap();

  const path = useMemo(
    () =>
      antPath(coords, {
        delay: 400,
        dashArray: [10, 20],
        weight: 5,
        color: "#0000FF",
        pulseColor: "#FFFFFF",
        paused: false,
        reverse: false,
        hardwareAccelerated: true,
      }),
    [coords]
  );

  useEffect(() => {
    map.addLayer(path);
    map.fitBounds(path.getBounds());
    return () => {
      map.removeLayer(path);
    };
  }, [map, path]);

  useEffect(() => {
    if (focus) map.flyTo(focus, map.getZoom());
  }, [map, focus]);

  return null;
};

//googlemaps API key
const key = "AIzaSyBVIFHm2YjPCPqIy3FY_WQ4OjGWsJpCHeU";

const TripMap: FC<{ trip?: ITrip; focus: [number, number] | undefined }> = ({
  trip,
  focus,
}) => {
  return (
    <>
      <MapContainer
        center={[35.0, 20.0]}
        zoom={5}
        maxZoom={15}
        id="map"
        style={{ width: "100%", height: "100%" }}
      >
        <ReactLeafletGoogleLayer apiKey={key} type={"roadmap"} />
        <AttributionControl position="bottomleft" />
        {trip?.cargo_position && (
          <>
            <FocusedMarker position={trip?.cargo_position.coordinates} />
            {trip.tripCoordsHistory.length && (
              <Path
                coords={trip.tripCoordsHistory.map(
                  (el) => el.coords.coordinates
                )}
                focus={focus}
              />
            )}
          </>
        )}
      </MapContainer>
    </>
  );
};

export default TripMap;
