import React, { FC, useMemo } from "react";
import { Loader } from "@periplus/ui-library";
import { makeStyles, Box } from "@material-ui/core";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { TripForm } from "./components";
import { useParams } from "react-router-dom";
import useGetTrips from "graphql/trips/useGetTrips";

const useStyles = makeStyles({
  root: {
    paddingLeft: 180,
    paddingRight: 180,
  },
});

const TripInsertAction: FC = () => {
  const { trip_id } = useParams<{ trip_id: string }>();
  const { urlSearchParams } = useUrlSearchParams();
  const classes = useStyles();

  const {
    data: {
      trips: [trip],
    },
    loading: tripLoading,
  } = useGetTrips({ trip_id });

  const { schema, ui_schema } = useMemo(() => {
    const { params_schema, ui_schema } =
      trip?.available_actions?.find(
        (el) => el.action === urlSearchParams.trip_action
      ) || {};
    return {
      schema: params_schema,
      ui_schema,
    };
  }, [trip, urlSearchParams.trip_action]);

  return (
    <Box className={classes.root}>
      {tripLoading || !schema ? (
        <Loader />
      ) : (
        <TripForm
          trip_id={trip_id}
          trip_type={trip.trip_type}
          trip_action={urlSearchParams.trip_action}
          schema={schema}
          //@ts-ignore
          uiSchema={ui_schema}
        />
      )}
    </Box>
  );
};

export default TripInsertAction;
