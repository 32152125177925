import React from "react";
import {
  AttributionControl,
  MapContainer,
  MapContainerProps,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

//googlemaps API key
const key = "AIzaSyBVIFHm2YjPCPqIy3FY_WQ4OjGWsJpCHeU";

interface ICustomMapProps {
  whenReady?: any;
  zoom?: number;
  center?: [number, number];
}

type IMapProps = MapContainerProps & ICustomMapProps;

const defaultCenter = [35.0, 20.0] as [number, number];

const Map: React.FC<IMapProps> = ({
  children,
  bounds = undefined,
  zoom = 5,
  center = defaultCenter,
  whenReady,
  style,
  className,
}) => {
  return (
    <MapContainer
      whenReady={whenReady}
      //@ts-ignore
      center={center}
      zoom={zoom}
      id="map"
      className={className}
      style={{ width: "100%", height: "100%", ...style }}
    >
      <ReactLeafletGoogleLayer apiKey={key} type="roadmap" />
      <AttributionControl position="bottomright" />
      {children}
    </MapContainer>
  );
};

export default Map;
