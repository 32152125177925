// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
import { SasStore } from "utils/blockBlobClient";

const prepareData = (
  fileUrl: string,
  contentType?: string
): { sasContentType?: string; containerNameExtracted?: string } => {
  const containerNameExtracted = fileUrl.split("/")[3];

  /*
   * doc  application/msword
   * xls  application/vnd.ms-excel
   * docx application/vnd.openxmlformats-officedocument.wordprocessingml.document
   * xlsx application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   * */

  const extension = fileUrl.split(".").pop();
  /* as browsers can't open non-PDF's ignoring this setting */
  const sasContentType = extension === "pdf" ? contentType : undefined;

  return {
    sasContentType,
    containerNameExtracted,
  };
};

export const useGetDownloadUrl = (
  fileUrl: string,
  contentType?: string,
  contentDisposition?: string
): {
  url?: string;
  loading: boolean;
} => {
  const [url, setUrl] = useState<string>();

  if (!fileUrl) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const sasStore = new SasStore();

  const sasToken = sasStore.getValidSASForContainer(
    containerNameExtracted,
    sasContentType,
    contentDisposition
  );

  if (sasToken) {
    return {
      url: `${fileUrl}?${sasToken}`,
      loading: false,
    };
  }

  sasStore
    .updateSASForContainer(
      containerNameExtracted,
      sasContentType,
      contentDisposition
    )
    .then((sasToken) => setUrl(`${fileUrl}?${sasToken}`));

  return {
    url: `${fileUrl}?sv=2020-02-10&ss=b&srt=sco&sp=rwdlacx&se=2021-06-07T15:47:44Z&st=2021-04-07T07:47:44Z&spr=https&sig=gYvhd6o%2FVl67SdpxK9Kwo9F85ZJvpJA0ZqWofDistHo%3D`, //${sasToken}
    loading: !url,
  };
};

export const downloadBlob = async (fileUrl: string, idToken: any) => {
  const response = await fetch(fileUrl, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

const getDownloadUrl = (fileUrl: string, contentType?: string): string => {
  // const { sasContentType, containerNameExtracted } = prepareData(
  //   fileUrl,
  //   contentType
  // );

  // if (!containerNameExtracted) {
  //   return fileUrl;
  // }

  // const sasStore = new SasStore();

  // const sasToken = sasStore.getValidSASForContainer(
  //   containerNameExtracted,
  //   sasContentType
  // );
  return `${fileUrl}?sv=2020-02-10&ss=b&srt=sco&sp=rwdlacx&se=2021-06-07T15:47:44Z&st=2021-04-07T07:47:44Z&spr=https&sig=gYvhd6o%2FVl67SdpxK9Kwo9F85ZJvpJA0ZqWofDistHo%3D`; //${sasToken}
};

export default getDownloadUrl;
