import React, { FC, useEffect, memo, useState } from "react";
import mermaid from "mermaid";
import clsx from "clsx";

const DEFAULT_CONFIG = {
  theme: "neutral",
  flowchart: {
    useMaxWidth: true,
    htmlLabels: true,
  },
};

interface IMermaidProps {
  name?: string;
  config?: any;
  className?: string;
  chart?: string;
}

const Mermaid: FC<IMermaidProps> = memo(
  ({ name = "mermaid", config, className, chart }) => {
    mermaid.initialize({ ...DEFAULT_CONFIG, ...config });
    const [diagram, setDiagram] = useState(chart);

    useEffect(() => {
      mermaid.contentLoaded();
    }, [config]);

    useEffect(() => {
      const { mermaidAPI } = mermaid;

      if (!chart) {
        return;
      }

      mermaidAPI.render(name, chart, (some) => {
        if (some) {
          setDiagram(some);
        }
      });
    }, [chart, name]);

    if (!diagram) return null;
    return (
      <div
        className={clsx("mermaid", className)}
        dangerouslySetInnerHTML={{ __html: diagram }}
      />
    );
  }
);

export default Mermaid;
