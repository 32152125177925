import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { UPDATE_TRIP_TYPE_ACTION } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useUpdateTripTypeAction = () => {
  const [update] = useMutation(UPDATE_TRIP_TYPE_ACTION);
  const withErrorHandling = useErrorHandling();

  const updateTripTypeAction = useCallback(
    async (variables: {
      action_name: string;
      params_schema: string;
      ui_schema: string;
    }) => {
      withErrorHandling(update, {
        variables,
      });
    },
    [update, withErrorHandling]
  );

  return updateTripTypeAction;
};

export default useUpdateTripTypeAction;
