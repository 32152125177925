import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { UiLibraryProvider } from "@periplus/ui-library";
import { SnackbarProvider } from "notistack";
import theme from "styles";
import Routes from "./Routes";
import { useTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UiLibraryProvider locale={language}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </UiLibraryProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
