import React, { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useGetOrganizations from "graphql/hooks/useGetOrganizations";
import useGetOrgType from "graphql/hooks/useGetOrgType";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Text from "components/Text/Text";
import Table from "components/Table/Table";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import { DELETE_ORGANIZATION } from "graphql/mutations/organization";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";

import { ActionBar, ActionBarItem, Button, IconButton, Search } from "@periplus/ui-library";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import OrganizationDialog from "./components/OrganizationDialog";

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    fontWeight: 500,
    fontSize: 14,
  },
  tableCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: 14,
  },
}));

const ErrorScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<any>();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION);
  const { enqueueSnackbar } = useSnackbar();

  const limit = useMemo(
    () => parseInt(localStorage.getItem("userManagment_itemsPerPage") || "6"),
    []
  );

  const { data: org_type } = useGetOrgType();

  const {
    data: { organizations, total_rows },
    loading,
    loadData,
    refetch,
  } = useGetOrganizations({ limit });

  const generateAddress = useCallback((meta) => {
    return [
      meta?.address,
      meta?.addresssupplement1,
      meta?.addresssupplement2,
      meta?.addresssupplement3,
      meta?.street,
      meta?.city,
      meta?.country,
      meta?.postalcode,
    ]
      .filter((address) => address)
      .join(' ');
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        Header: <Text className={classes.tableHeader}>common:id</Text>,
        accessor: "org_id",
      },
      {
        Header: <Text className={classes.tableHeader}>common:org_name</Text>,
        accessor: "org_name",
        style: {
          minWidth: 150,
        },
        Cell: ({ cell }: any) => (
          <div className={classes.tableCell} title={cell.value}>
            {cell.value}
          </div>
        ),
      },
      {
        Header: <Text className={classes.tableHeader}>common:type</Text>,
        accessor: "org_type",
      },
      {
        Header: <Text className={classes.tableHeader}>common:address</Text>,
        accessor: "meta",
        style: {
          minWidth: 250,
        },
        Cell: ({ row: { original } }: { row: { original: any } }) => {
          return (
            <div className={classes.tableCell}>
              {" "}
              {generateAddress(original.meta)}{" "}
            </div>
          );
        },
      },
      {
        Header: (
          <Text className={classes.tableHeader}>
            common:traderidentificationnumber
          </Text>
        ),
        accessor: "meta.traderidentificationnumber",
      },
      {
        Header: <Text className={classes.tableHeader}>common:actions</Text>,
        accessor: "actions",
        disableSortBy: true,
        style: {
          width: "115px",
        },
        Cell: ({ row: { original } }: { row: { original: any } }) => {
          return (
            <>
              <IconButton
                size="sm"
                onClick={() => {
                  setSelectedOrganization(original);
                  setIsOpenFormDialog(true);
                }}
                title={t("common:edit")}
              >
                <CreateOutlinedIcon color="action" />
              </IconButton>
              <IconButton
                size="sm"
                onClick={() => {
                  setSelectedOrganization(original);
                  setIsOpenDeleteDialog(true);
                }}
                title={t("common:delete")}
              >
                <DeleteOutlineIcon color="action" />
              </IconButton>
            </>
          );
        },
      },
    ],
    [classes.tableCell, classes.tableHeader, t, generateAddress]
  );

  const onChangeItemsPerPage = (itemsPerPage: number) => {
    localStorage.setItem("userManagment_itemsPerPage", itemsPerPage.toString());
  };

  const handleAdd = useCallback(() => {
    setIsOpenFormDialog(true);
  }, []);

  const handleCloseOrganizationFormDialog = useCallback(() => {
    setSelectedOrganization(undefined);
    setIsOpenFormDialog(false);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setSelectedOrganization(undefined);
    setIsOpenDeleteDialog(false);
  }, []);

  const handleConfirmDeleteDialog = useCallback(() => {
    deleteOrganization({
      variables: {
        org_id: selectedOrganization && selectedOrganization.org_id,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
      });
    setSelectedOrganization(undefined);
    setIsOpenDeleteDialog(false);
  }, [
    t,
    refetch,
    setSelectedOrganization,
    deleteOrganization,
    enqueueSnackbar,
    selectedOrganization,
  ]);

  return (
    <Grid item container direction="column">
      {isOpenFormDialog && (
        <OrganizationDialog
          org_type={org_type}
          organization={selectedOrganization}
          onClose={handleCloseOrganizationFormDialog}
          refetch={refetch}
          onConfirm={() => {}}
        />
      )}

      {isOpenDeleteDialog && (
        <ConfirmationDialog
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDeleteDialog}
          message={t(`organizationsManagment:deleteDialogMessage`)}
        />
      )}

      <ActionBar justify="flex-end">
        <ActionBarItem>
          <Search
            variant="outlined"
            size="sm"
            value={search}
            onChange={setSearch}
            style={{ width: 180 }}
          />
          <Button
            variant="contained"
            size="sm"
            color="primary"
            onClick={handleAdd}
            style={{ marginLeft: 10 }}
            endIcon={<AddIcon fontSize="small" />}
          >
            {t("common:add")}
          </Button>
        </ActionBarItem>
      </ActionBar>
      <Box style={{ marginTop: 15 }}>
        <Table
          columnDefs={columnDefs}
          rowData={organizations}
          totalItems={total_rows}
          initialItemsPerPage={limit}
          loadMore={loadData}
          loading={loading}
          onChangeItemsPerPage={onChangeItemsPerPage}
          search={search}
        />
      </Box>
    </Grid>
  );
};

export default ErrorScreen;
