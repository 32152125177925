import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useGetTripType } from "graphql/tripType";
import { useParams } from "react-router-dom";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Editor from "pages/Settings/EditActionSchema/Components/Editor";
import { fromJson, toJson } from "utils/json";
import { TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY } from "pages/Dashboard/components/TripsPanel/TripsPanel";
import { capitalize } from "lodash";

const getTripTypeColumns = (tripType) => {
  return Object.keys(tripType?.trip_schema.properties || {}).map((column) => {
    const columnProperties = tripType?.trip_schema.properties[column];
    const typeVariable = columnProperties.format || columnProperties.type;

    return {
      name: column,
      title: columnProperties.title || column,
      type: typeVariable
        ? capitalize(typeVariable.split("-").join(" "))
        : "$ref",
    };
  });
};

const EditTripTypeSchema: FC = () => {
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();
  const {
    data: {
      trip_types: [trip_type],
    },
    loading,
  } = useGetTripType({ trip_type: tripTypeName });

  const [
    tripTypeColumnsCustomization,
    setTripTypeColumnCustomization,
  ] = useState(
    fromJson(
      localStorage.getItem(
        `${TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY}/${tripTypeName}`
      ) || "{}"
    )
  );

  const handleChangeColumnVisibility = (name: string) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setTripTypeColumnCustomization((prevValues) => ({
      ...prevValues,
      [name]: {
        visible: e.target.checked,
      },
    }));
  };

  useEffect(() => {
    return () => {
      localStorage.setItem(
        `${TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY}/${tripTypeName}`,
        toJson(tripTypeColumnsCustomization)
      );
    };
  }, [tripTypeColumnsCustomization, tripTypeName]);

  if (loading) {
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={12}>
          <Skeleton variant="rect" height={500} width="100%" />
        </Grid>
        <Grid item md={6} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...new Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item md={6} xs={12}>
        <Editor
          height={500}
          title="JSON Schema"
          code={toJson(trip_type.trip_schema)}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Column</TableCell>
              <TableCell>Visible</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTripTypeColumns(trip_type).map(
              ({ name, title, type }, index) => (
                <TableRow key={index}>
                  <TableCell>{title || name}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={
                        tripTypeColumnsCustomization[name]?.visible ?? true
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={handleChangeColumnVisibility(name)}
                    />
                  </TableCell>
                  <TableCell>{type}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default EditTripTypeSchema;
