import { useMemo } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const GET_ORG_TYPE = gql`
  query GET_ORG_TYPE {
    org_type {
      org_type
    }
  }
`;

const useGetOrgType = () => {
  const { data, loading, error, refetch } = useQuery(GET_ORG_TYPE, {
    fetchPolicy: "no-cache"
  });

  const returnValue = useMemo(() => {
    const org_type = (data && data.org_type) || [];
    return {
      data: org_type,
      loading,
      error,
      refetch
    };
  }, [data, loading, error, refetch]);

  return returnValue;
};

export default useGetOrgType;
