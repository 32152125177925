import gql from "graphql-tag";

export const GET_TRIP_STATE = gql`
  query GET_TRIP_STATE($trip_type: String) {
    trip_type_state(where: { trip_type: { _eq: $trip_type } }) {
      display_name
      trip_state
      trip_type
      description
    }
  }
`;

export const GET_TRIP_STATE_COORDS = gql`
  query GET_TRIP_STATE_COORDS($trip_type: String, $trip_state: String) {
    trip_type_state_coord(
      where: {
        _and: {
          trip_type: { _eq: $trip_type }
          trip_state: { _eq: $trip_state }
        }
      }
    ) {
      coords
      notes
      trip_state
      trip_type
    }
  }
`;

export const CREATE_TRIP_STATE = gql`
  mutation CREATE_TRIP_STATE($tripStates: [trip_type_state_insert_input!]!) {
    insert_trip_type_state(
      on_conflict: {
        constraint: pk_trip_type_state_trip_type_trip_state
        update_columns: [description, display_name]
      }
      objects: $tripStates
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_TRIP_STATE_COORDS = gql`
  mutation UPSERT_TRIP_STATE_COORDS(
    $tripStateCoords: [trip_type_state_coord_insert_input!]!
  ) {
    insert_trip_type_state_coord(
      objects: $tripStateCoords
      on_conflict: {
        constraint: pk_trip_type_state_coords
        update_columns: [notes, coords]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_TRIP_STATE_COORDS = gql`
  mutation DELETE_TRIP_STATE_COORDS($trip_state: String, $trip_type: String) {
    delete_trip_type_state_coord(
      where: {
        trip_state: { _eq: $trip_state }
        trip_type: { _eq: $trip_type }
      }
    ) {
      affected_rows
    }
  }
`;
