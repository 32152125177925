import React, { FC, useEffect, useState } from "react";
import { Dialog } from "@periplus/ui-library";
import { Button } from "@material-ui/core";
import { Marker, useMapEvents } from "react-leaflet";
import { LocationEvent } from "leaflet";
import { toJson } from "utils/json";

import Field from "components/Field";
import Map from "components/Map";

interface iMapDialog {
  open?: boolean;
  onClose?: () => void;
  onSave?: (latlng: [number, number], userNotes?: string) => void;
  coordinates?: [number, number];
  title: string;
  notes?: string;
}

const MapSelector = ({
  coordinates,
  zoom = 12,
  onLocationSelect,
  readonly,
  onUserCoordinatesGet,
}: {
  readonly?: boolean;
  zoom?: number;
  coordinates: [number, number];
  onLocationSelect?: (e: LocationEvent) => void;
  onUserCoordinatesGet?: (e: LocationEvent) => void;
}) => {
  const events = readonly
    ? {}
    : {
        click: (e) => {
          onLocationSelect?.(e);
        },
        locationfound: (e) => onUserCoordinatesGet?.(e),
      };

  const map = useMapEvents(events);
  map.locate();

  if (coordinates.length > 0) {
    map.fitBounds([coordinates], { maxZoom: zoom });
  }

  return null;
};

const initialObj = {
  initial: [],
  userCoordinates: [],
};
const MapDialog: FC<iMapDialog> = ({
  open = false,
  onClose,
  onSave,
  coordinates,
  notes = "",
  title,
}) => {
  const [userNotes, setUserNotes] = useState(notes);
  const [userCurrentCoordinates, setUserCurrentCoordinates] = useState<any>([]);
  const [selectedCoordinates, setCoordinates] = useState<any>(initialObj);

  useEffect(() => {
    setCoordinates({
      userCoordinates: coordinates,
      initial: coordinates,
    });
  }, [coordinates]);

  const handleClose = () => {
    onClose?.();
    setCoordinates(initialObj);
  };

  const handleChange = (e, value) => {
    setUserNotes(value);
  };

  useEffect(() => {
    setUserNotes(notes);
  }, [notes]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      headerTitle={`Selecting coordinates for state: "${title}"`}
      actions={
        <>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              toJson(selectedCoordinates.userCoordinates) ===
                toJson(selectedCoordinates.initial) && userNotes === notes
            }
            onClick={() => {
              onSave?.(selectedCoordinates.userCoordinates, userNotes);
              handleClose();
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Map style={{ height: 560, marginBottom: 8 }}>
        {selectedCoordinates.userCoordinates?.length > 0 && (
          <Marker
            position={selectedCoordinates.userCoordinates}
            eventHandlers={{
              click: () => {
                setCoordinates((rest) => ({
                  ...rest,
                  userCoordinates: [],
                }));
              },
            }}
          />
        )}
        <MapSelector
          coordinates={
            selectedCoordinates.userCoordinates?.length > 0
              ? selectedCoordinates.userCoordinates
              : userCurrentCoordinates
          }
          zoom={selectedCoordinates.initial?.length === 0 ? 8 : 12}
          onLocationSelect={({ latlng }) =>
            setCoordinates((prev) => ({
              ...prev,
              userCoordinates: [latlng.lat, latlng.lng],
            }))
          }
          onUserCoordinatesGet={({ latlng }) =>
            setUserCurrentCoordinates([latlng.lat, latlng.lng])
          }
        />
      </Map>
      <Field
        label="Notes"
        value={userNotes}
        onChange={handleChange}
        name="notes"
      />
    </Dialog>
  );
};

export default MapDialog;
