import React, { FC, useLayoutEffect } from "react";
import { ITripActionV } from "graphql/tripAction";
import Mermaid from "components/Mermaid";

function fillDiagramNodes(doneTripStates, activeStep) {
  const statesElements = document.querySelectorAll(".node.statediagram-state");
  if (statesElements.length > 0) {
    const passedStateElements = [] as any[];

    statesElements.forEach((activeStateElement) => {
      const nodeLabel =
        // @ts-ignore
        activeStateElement.querySelector(".nodeLabel")?.innerText;

      if (!nodeLabel) {
        return;
      }

      if (doneTripStates.includes(nodeLabel)) {
        passedStateElements.push(activeStateElement);
      }
    });

    passedStateElements.forEach((stateElement) => {
      const nodeLabel =
        // @ts-ignore
        stateElement.querySelector(".nodeLabel")?.innerText;
      let fill = "rgb(96 197 80)";
      let stroke = "rgb(50 115 29)";
      if (nodeLabel.includes(activeStep)) {
        fill = "rgb(34, 174, 248)";
        stroke = "rgb(10, 89, 131)";
      }

      stateElement
        .querySelector(".title-state")
        .setAttribute("style", `fill:${fill};stroke:${stroke};`);
      stateElement
        .querySelector(".divider")
        .setAttribute("style", `stroke:${stroke};`);
    });
  }
}

interface IDiagramProps {
  tripActions: ITripActionV[];
  diagram: string;
  activeStep: string;
}

const Diagram: FC<IDiagramProps> = ({ tripActions, diagram, activeStep }) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      const passedTripStates = tripActions.map(({ tripTypeState }) =>
        tripTypeState.display_name?.trim()
      );
      fillDiagramNodes(passedTripStates, activeStep);
    });
  }, [tripActions, activeStep]);

  return <Mermaid chart={diagram} />;
};

export default Diagram;
