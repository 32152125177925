import React, { FC } from "react";
import { KeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { KeycloakInstance, KeycloakInitOptions } from "keycloak-js";
import Cookies from "js-cookie";

import config from "config";

import { AuthProvider } from "./context/AuthContext";

export const keycloak: KeycloakInstance = Keycloak({
  url: config.keycloak.url,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
  // onLoad: "login-required",
  // flow: "implicit",
  // responseType: "id_token token"
});

// https://github.com/panz3r/react-keycloak/issues/11
// https://www.keycloak.org/docs/latest/securing_apps/index.html
const onEvent = (event: any, error: any) => {
  if (typeof error !== undefined) {
    switch (event) {
      case "onAuthSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshError":
        console.log("onAuthRefreshError");
        break;
      case "onAuthError":
        console.log("onAuthError");
        break;
      default:
        break;
    }
  }
};

const refreshToken = Cookies.get("refreshToken");
const token = Cookies.get("token");
const idToken = Cookies.get("idToken");

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  //@ts-ignore
  promiseType: "legacy",
  // @ts-ignore
  token: token,
  // @ts-ignore
  refreshToken: refreshToken,
  // @ts-ignore
  idToken: idToken,
};

const Provider: FC = ({ children }) => (
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={keycloakProviderInitConfig}
    onEvent={onEvent}
  >
    <AuthProvider>{children}</AuthProvider>
  </KeycloakProvider>
);

export default Provider;
