import gql from "graphql-tag";

export const GET_TRIP_TYPES = gql`
  query GET_TRIP_TYPES($trip_type: String) {
    pp_trip_types(where: { trip_type: { _eq: $trip_type } }) {
      trip_type
      description
      display_name
      trip_schema
      start_state
      start_action
      start_action_display_name
      start_params
      trip_type_states(order_by: { state_order: asc }) {
        display_name
        description
        trip_state
        state_order
        meta
      }
    }
  }
`;

export const GET_TRIP_TYPE = gql`
  query GET_TRIP_TYPE($trip_type: String) {
    trip_type(where: { trip_type: { _eq: $trip_type } }) {
      trip_type
      description
      display_name
      trip_schema
      start_state
    }
  }
`;

export const CREATE_TRIP_TYPE = gql`
  mutation CREATE_TRIP_TYPE(
    $trip_type: String
    $display_name: String
    $start_state: String
    $description: String
  ) {
    insert_trip_type(
      objects: {
        trip_type: $trip_type
        display_name: $display_name
        start_state: $start_state
        description: $description
      }
      on_conflict: {
        constraint: trip_type_pkey
        update_columns: [display_name, start_state, description]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_TRIP_TYPE = gql`
  mutation UPDATE_TRIP_TYPE(
    $initial_trip_type: String
    $trip_type: String
    $display_name: String
    $description: String
    $start_state: String
  ) {
    update_trip_type(
      where: { trip_type: { _eq: $initial_trip_type } }
      _set: {
        trip_type: $trip_type
        start_state: $start_state
        display_name: $display_name
        description: $description
      }
    ) {
      affected_rows
    }
  }
`;
