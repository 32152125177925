import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { ITripActionV } from "graphql/tripAction";
import TripAction from "./components/TripAction";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
  tripAction: {
    "&:not(:first-child)": {
      marginTop: 16,
    },
  },
});

interface TripActionsProps {
  tripActions: ITripActionV[];
  onChangeMapFocus: (coord: [number, number]) => void;
}

const TripActions: FC<TripActionsProps> = ({
  tripActions,
  onChangeMapFocus,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {tripActions.map((tripAction, index) => (
        <TripAction
          className={classes.tripAction}
          tripAction={tripAction}
          initialIsExpanded={tripActions.length === index + 1}
          key={`${tripAction.trip_id}/${tripAction.action_name}/${tripAction.created}`}
          onChangeMapFocus={onChangeMapFocus}
        />
      ))}
    </Box>
  );
};

export default TripActions;
