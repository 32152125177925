import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  MenuItem,
  makeStyles,
  Theme,
  Box,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  ActionBar,
  ActionBarItem,
  Typography,
  TextField,
  Label,
  DatePickerRange,
  Search,
  Button,
  Table,
  Dialog,
} from "@periplus/ui-library";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import moment from "moment-timezone";
import { ITrip, useGetTrips } from "graphql/trips";
import { ITripTypes } from "graphql/tripType";
import { Link } from "react-router-dom";
import { Launch } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import clsx from "clsx";
import useDeleteTripAction from "graphql/trips/useDeleteTripAction";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { CustomizeTableDialog } from "./components";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) => ({
  tripsPanel: {
    flexGrow: 1,
  },
  additionalInfoPanel: {
    marginLeft: 36,
  },
  filterTitle: {
    fontSize: 14,
    color: theme.palette.grey4.main,
    "&:not(:first-child)": {
      marginLeft: 10,
    },
  },
  statusFilter: {
    width: 180,
    marginLeft: 10,
  },
  filterDivider: {
    marginLeft: 10,
  },
  selectedStatus: {
    margin: 0.5,
  },
  tableContainer: {
    marginTop: 16,
  },
  listItem: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    minHeight: 67,
    backgroundColor: "#fff",
    borderRadius: 4,
    "&:not(:first-child)": {
      marginTop: theme.spacing(1),
    },
  },
  listItemField: {
    padding: "10px 16px",
    wordBreak: "break-all",
  },
  tripCode: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "17px",
    letterSpacing: "0.25px",
    color: "#221ECD",
  },
  tripName: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "25px",
    letterSpacing: "0.15px",
    color: "#333333",
  },
  tripAction: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    "&:first-child": {
      marginLeft: "auto",
    },
    "&:not(:first-child)": {
      marginLeft: 10,
    },
  },
  tripActionIcon: {
    border: "0.5px solid #716EDE",
    borderRadius: 4,
    padding: 3,
  },
  tripActionIconButton: {
    color: "#716EDE",
  },
  lastColumn: {
    position: "sticky",
    zIndex: 1,
    right: 0,
    backgroundColor: "#fff",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      right: -8,
      width: 8,
      height: "100%",
      zIndex: 1,
      backgroundColor: "#fff",
      borderBottom: "1px solid #fff",
      top: 0,
    },
  },
  startTripAction: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    marginLeft: 10,
  },
  stateInputRoot: {
    "& .MuiAutocomplete-input": {
      minWidth: "unset",
    },
  },
}));

export const TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY =
  "tripTypeColumnsCustomization";

const getCustomizedTripTypeColumns = (tripType) => {
  const tripTypeColumnsCustomization = JSON.parse(
    localStorage.getItem(
      `${TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY}/${tripType?.trip_type}`
    ) || "{}"
  );
  return Object.keys(tripType?.trip_schema.properties || {}).map((column) => ({
    name: column,
    visible: tripTypeColumnsCustomization[column]?.visible ?? true,
    title: tripType?.trip_schema.properties[column].title || column,
  }));
};

const TripsPanel: FC<{
  trip_types: ITripTypes[];
  selectedTrip?: ITrip;
  onChangeSelectedTrip: (trip: ITrip) => void;
}> = ({ trip_types, selectedTrip, onChangeSelectedTrip }) => {
  const classes = useStyles();
  const [
    removeLastActionDialogState,
    setRemoveLastActionDialogState,
  ] = useState<{ trip_id: any; action_name: string }>();
  const [isOpenCustomizeTableDialog, setIsOpenCustomizeTableDialog] = useState(
    false
  );

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<{
    itemsPerPage: string;
    page: string;
    trip_type: string;
    state?: string;
    search?: string;
    startDate?: string;
    endDate?: string;
    sortBy: string;
  }>({
    page: "1",
    itemsPerPage: localStorage.getItem("dashboardItemsPerPage") || "6",
    trip_type: trip_types[0]?.trip_type,
    sortBy: JSON.stringify([{ key: "trip_id", direction: "DESC" }]),
  });

  const deleteTripAction = useDeleteTripAction();

  const {
    data: { trips, trips_total_count },
    loading: tripsLoading,
    refetch: tripsRefetch,
  } = useGetTrips({
    limit: +urlSearchParams.itemsPerPage,
    offset: (+urlSearchParams.page - 1) * +urlSearchParams.itemsPerPage,
    trip_type: urlSearchParams.trip_type,
    state: urlSearchParams.state && JSON.parse(urlSearchParams.state),
    date_min: urlSearchParams.startDate,
    date_max: urlSearchParams.endDate,
    search: urlSearchParams.search,
    orderby:
      urlSearchParams.sortBy && JSON.parse(urlSearchParams.sortBy)[0].key,
    desc:
      urlSearchParams.sortBy &&
      JSON.parse(urlSearchParams.sortBy)[0].direction === "DESC",
  });

  const selectedTripType = useMemo(
    () =>
      trip_types.find(
        ({ trip_type }) => trip_type === urlSearchParams.trip_type
      ),
    [urlSearchParams, trip_types]
  );

  const [customizedTripTypeColumns, setCustomizedTripTypeColumns] = useState<
    { name: string; visible: boolean; title: string }[]
  >(getCustomizedTripTypeColumns(selectedTripType));

  useEffect(() => {
    setCustomizedTripTypeColumns(
      getCustomizedTripTypeColumns(selectedTripType)
    );
  }, [selectedTripType]);

  const handleSortChange = useCallback(
    (value) =>
      setUrlSearchParams({
        sortBy: JSON.stringify(value),
      }),
    [setUrlSearchParams]
  );

  const handleFilterChange = useCallback(
    (filter: string) => (value: any) => {
      const defaultUrlSearchParams = {
        page: "1",
      };
      switch (filter) {
        case "page":
          setUrlSearchParams({ page: value });
          return;
        case "itemsPerPage":
          setUrlSearchParams({
            ...defaultUrlSearchParams,
            itemsPerPage: value,
          });
          localStorage.setItem("dashboardItemsPerPage", value);
          return;
        case "trip_type":
          setUrlSearchParams({
            ...defaultUrlSearchParams,
            trip_type: value.target.value,
            state: undefined,
          });
          return;
        case "state":
          setUrlSearchParams({
            ...defaultUrlSearchParams,
            state: JSON.stringify(value),
          });
          return;
        case "date_range":
          setUrlSearchParams({
            ...defaultUrlSearchParams,
            startDate: value.startDate?.format(),
            endDate: value.endDate?.format(),
          });
          return;
        case "search":
          setUrlSearchParams({ ...defaultUrlSearchParams, search: value });
          return;
      }
    },
    [setUrlSearchParams]
  );

  const columnDefs = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "trip_id",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ row }) => {
          return (
            // {row.original.state_meta.color} TODO: back to color prop
            <Label color="#22aef8">
              {row.original.tripTypeState.display_name}
            </Label>
          );
        },
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {moment(row.original.created).format("DD.MM.YYYY")}
            </Typography>
          );
        },
      },
      ...customizedTripTypeColumns
        .filter((customizedTripTypeColumn) => customizedTripTypeColumn.visible)
        .map((column) => ({
          Header: column.title,
          accessor: column.name,
          Cell: ({ row }) => {
            return (
              <Typography variant="body2">
                {JSON.stringify(row.original.trip_data?.[column.name])}
              </Typography>
            );
          },
        })),
      {
        Header: "Actions",
        accessor: "available_actions",
        Cell: ({ row }) => {
          return (
            <Box display="flex">
              {row.original.available_actions &&
                row.original.available_actions.map((available_action) => {
                  return (
                    <Link
                      className={classes.tripAction}
                      to={`/trips/${row.original.trip_id}/new-action?trip_action=${available_action.action}`}
                      key={available_action.action}
                    >
                      <Button variant="contained" color="primary" size="md">
                        {available_action.display_name}
                      </Button>
                    </Link>
                  );
                })}
              {row.original.edit_action?.action && (
                <>
                  <IconButton
                    className={clsx(classes.tripAction, classes.tripActionIcon)}
                    title="Cancel last action"
                    onClick={() =>
                      setRemoveLastActionDialogState({
                        trip_id: row.original.trip_id,
                        action_name: row.original.edit_action.action,
                      })
                    }
                  >
                    <SettingsBackupRestoreIcon
                      className={classes.tripActionIconButton}
                    />
                  </IconButton>
                  <Link
                    className={classes.tripAction}
                    to={`/trips/${row.original.trip_id}/edit`}
                    title="Edit"
                  >
                    <IconButton className={classes.tripActionIcon}>
                      <EditOutlinedIcon
                        className={classes.tripActionIconButton}
                      />
                    </IconButton>
                  </Link>
                </>
              )}
              <Link
                className={classes.tripAction}
                to={`/trips/${row.original.trip_id}`}
              >
                <IconButton className={classes.tripActionIcon}>
                  <Launch className={classes.tripActionIconButton} />
                </IconButton>
              </Link>
            </Box>
          );
        },
        className: classes.lastColumn,
      },
    ],
    [
      classes.tripActionIcon,
      classes.tripActionIconButton,
      classes.tripAction,
      classes.lastColumn,
      customizedTripTypeColumns,
    ]
  );

  return (
    <Box display="flex" flexDirection="column">
      {removeLastActionDialogState && (
        <Dialog
          open={true}
          onClose={() => setRemoveLastActionDialogState(undefined)}
          variant="warning"
          actions={
            <>
              <Button
                variant="contained"
                size="md"
                onClick={() => setRemoveLastActionDialogState(undefined)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="md"
                color="primary"
                onClick={async () => {
                  await deleteTripAction({
                    trip_id: removeLastActionDialogState.trip_id,
                    action_name: removeLastActionDialogState.action_name,
                  });
                  await new Promise((resolve) => setTimeout(resolve, 500)); //TODO
                  await tripsRefetch();
                  setRemoveLastActionDialogState(undefined);
                }}
              >
                Confirm
              </Button>
            </>
          }
        >
          Are you sure you want to remove last action?
        </Dialog>
      )}
      {isOpenCustomizeTableDialog && (
        <CustomizeTableDialog
          onClose={() => setIsOpenCustomizeTableDialog(false)}
          onConfirm={(newCustomizedTripTypeColumns) => {
            setIsOpenCustomizeTableDialog(false);
            setCustomizedTripTypeColumns(newCustomizedTripTypeColumns);
            localStorage.setItem(
              `${TRIP_TYPE_COLUMNS_CUSTOMIZATION_KEY}/${selectedTripType?.trip_type}`,
              JSON.stringify(
                newCustomizedTripTypeColumns.reduce(
                  (acc, newCustomizedTripTypeColumn) => ({
                    ...acc,
                    [newCustomizedTripTypeColumn.name]: {
                      visible: newCustomizedTripTypeColumn.visible,
                    },
                  }),
                  {}
                )
              )
            );
          }}
          columns={customizedTripTypeColumns}
        />
      )}
      <ActionBar>
        <ActionBarItem container alignItems="center">
          <Typography className={classes.filterTitle}>Good:</Typography>
          <TextField
            className={classes.statusFilter}
            value={urlSearchParams.trip_type}
            onChange={handleFilterChange("trip_type")}
            size="lg"
            variant="outlined"
            select
            SelectProps={{
              MenuProps: {
                variant: "menu",
              },
            }}
          >
            {trip_types.map((trip_type) => (
              <MenuItem value={trip_type.trip_type} key={trip_type.trip_type}>
                {trip_type.display_name}
              </MenuItem>
            ))}
          </TextField>
          <Divider
            className={classes.filterDivider}
            orientation="vertical"
            flexItem
          />
          <Typography className={classes.filterTitle}>State:</Typography>
          <Autocomplete
            classes={{
              inputRoot: classes.stateInputRoot,
            }}
            style={{ width: 249, marginLeft: 8 }}
            multiple
            size="small"
            options={
              selectedTripType?.trip_type_states.map((el) => el.trip_state) ||
              []
            }
            filterSelectedOptions
            limitTags={3}
            //TODO Change value to object insted of just trip_state
            value={
              urlSearchParams.state ? JSON.parse(urlSearchParams.state) : []
            }
            onChange={(_, value) => handleFilterChange("state")(value)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="xs"
                multiline
                InputProps={{
                  ...params.InputProps,
                  style: {
                    ...(params.InputProps?.style || {}),
                    padding: "3px 60px 3px 6px",
                  },
                }}
              />
            )}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const trip_type_state = selectedTripType?.trip_type_states.find(
                  (el) => el.trip_state === option
                );
                return (
                  <Label
                    color={trip_type_state?.meta.color || ""}
                    {...getTagProps({ index })}
                    style={{ maxWidth: "80%" }}
                  >
                    {trip_type_state?.display_name || ""}
                  </Label>
                );
              })
            }
            getOptionLabel={(option) =>
              selectedTripType?.trip_type_states.find(
                (el) => el.trip_state === option
              )?.display_name || ""
            }
          />
          <Divider
            className={classes.filterDivider}
            orientation="vertical"
            flexItem
          />
          <DatePickerRange
            value={{
              startDate: urlSearchParams.startDate
                ? moment(urlSearchParams.startDate)
                : null,
              endDate: urlSearchParams.endDate
                ? moment(urlSearchParams.endDate)
                : null,
            }}
            onChange={handleFilterChange("date_range")}
            style={{ marginLeft: 8 }}
          />
          <Divider
            className={classes.filterDivider}
            orientation="vertical"
            flexItem
          />
          <IconButton onClick={() => setIsOpenCustomizeTableDialog(true)}>
            <ViewColumnIcon />
          </IconButton>
          <Typography variant="body2">Customize</Typography>
        </ActionBarItem>
        <ActionBarItem>
          <Search
            value={urlSearchParams.search}
            onChange={handleFilterChange("search")}
            size="lg"
            style={{ width: 180 }}
          />
          {selectedTripType?.start_action && (
            <Link
              className={classes.startTripAction}
              to={`/trips/create?trip_type=${urlSearchParams.trip_type}&trip_action=${selectedTripType?.start_action}`}
            >
              <Button variant="contained" color="primary" size="lg">
                {selectedTripType?.start_action_display_name}
              </Button>
            </Link>
          )}
        </ActionBarItem>
      </ActionBar>
      <Box className={classes.tableContainer}>
        <Table
          columnDefs={columnDefs}
          rowData={trips}
          page={+urlSearchParams.page}
          totalItems={trips_total_count}
          itemsPerPage={+urlSearchParams.itemsPerPage}
          onChangePage={handleFilterChange("page")}
          onChangeItemsPerPage={handleFilterChange("itemsPerPage")}
          loading={tripsLoading}
          disableMultiSort
          sortBy={JSON.parse(urlSearchParams.sortBy)}
          onChangeSort={handleSortChange}
          onToggle={(val) => onChangeSelectedTrip(val.selecting[0])}
          selectionType="single"
          isRowSelected={(rowData: ITrip) =>
            selectedTrip?.trip_id === rowData.trip_id
          }
        />
      </Box>
    </Box>
  );
};

export default TripsPanel;
