export const getSearchParams = (search: string) => {
  const result: { [key: string]: string } = {};
  search
    .substring(1)
    .split("&")
    .forEach((param) => {
      const [key, value] = param.split("=");
      if (!key) return;

      result[decodeURIComponent(key)] = decodeURIComponent(value);
    });

  return result;
};
