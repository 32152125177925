import React, { FC, useEffect, useState } from "react";
import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { IconButton, Tooltip } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";

interface ShareButtonProps {
  url: string;
  className: string;
}

const ShareButton: FC<ShareButtonProps> = ({ url, className }) => {
  const [copied, setCopied] = useState(false);

  const { url: downloadUrl } = useGetDownloadUrl(
    url,
    "application/pdf",
    `inline; filename="${encodeURI(url.split("/").pop() as string)}"`
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  return (
    <Tooltip title={copied ? "Copied" : "Copy Link To Clipboard"}>
      <CopyToClipboard text={downloadUrl || ""} onCopy={() => setCopied(true)}>
        <IconButton size="small">
          <ShareOutlinedIcon className={className} />
        </IconButton>
      </CopyToClipboard>
    </Tooltip>
  );
};

export default ShareButton;
