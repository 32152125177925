import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "pages/Login";

const NonAuthRoutes: FC = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/help" exact render={() => <div>Help</div>} />
      <Route component={() => <Redirect to="/login" />} />
    </Switch>
  );
};

export default NonAuthRoutes;
