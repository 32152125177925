import { useQuery } from "react-apollo";
import { GET_TRIPS } from "./queries";
import { ITrip } from "./types";

const useGetTrips = (variables) => {
  const { data, ...rest } = useQuery<{
    pp_trips: ITrip[];
  }>(GET_TRIPS, {
    variables: {
      ...variables,
      state: JSON.stringify(variables.state)
        ?.replaceAll("[", "{")
        .replaceAll("]", "}"),
    },
    fetchPolicy: "no-cache",
  });

  return {
    data: {
      trips:
        data?.pp_trips.map((trip) => ({
          ...trip,
          tripDocs: trip.tripDocs?.map((tripDocument) => ({
            ...tripDocument,
            url: tripDocument.url.replaceAll('"', ""),
          })),
        })) || [],
      trips_total_count: data?.pp_trips[0]?.qty || 0,
    },
    ...rest,
  };
};

export default useGetTrips;
