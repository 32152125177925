import React, { FC, useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  Grid,
  Button as MuiButton,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import TuneIcon from "@material-ui/icons/Tune";
import { Button } from "@periplus/ui-library";
import clsx from "clsx";

import ExpandablePanel from "components/ExpandablePanel";
import { ITripState } from "graphql/tripState";
import {
  useCreateTripTypeActions,
  ITripTypeActionV,
} from "graphql/tripTypeAction";
import { useGetActionRoles } from "graphql/actionRoles";
import { toJson } from "utils/json";
import SquareButton from "components/SquareButton";
import Field from "components/Field";

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    flex: "1 1 0px",
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  input: {
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& > p": {
      position: "absolute",
      top: "95%",
      margin: 0,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  inlineButton: {
    alignSelf: "baseline",
    padding: "8px 10px",
  },
  test: {
    "& > textarea": {
      padding: "4px 2px!important",
    },
  },
  link: {
    whiteSpace: "nowrap",
    textDecoration: "none",
  },
  buttonPlacement: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  chip: {
    maxWidth: "66%",
  },
}));

interface TripTypeProps {
  onSubmit?: () => void;
  trip_actions: ITripTypeActionV[];
  trip_states: ITripState[];
}

const TripActionsPage: FC<TripTypeProps> = ({
  onSubmit,
  trip_actions,
  trip_states,
}) => {
  const tripActionLength = trip_actions?.length || 0;
  const tripStateLength = trip_states?.length || 0;
  const isTripStatesProvided = tripStateLength > 0;
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();
  const classes = useStyles();
  const defaultActionObject: any = {
    action_name: "",
    action_roles: [],
    state_post: "",
    state_pre: "",
    trip_type: tripTypeName || "",
  };

  const createTripActions = useCreateTripTypeActions();

  const {
    data: { action_roles },
  } = useGetActionRoles();

  const [excludeStates, setExcludeStates] = useState<{
    state_pre: string[];
    state_post: string[];
  }>({
    state_pre: [],
    state_post: [],
  });
  const [expanded, setExpanded] = useState(isTripStatesProvided);
  const [tripActionsErrors, setTripActionsErrors] = useState<
    { [key: string]: string }[]
  >([]);
  const [tripActionsState, setTripActionsState] = useState<ITripTypeActionV[]>(
    []
  );

  useEffect(() => {
    const initialServerTripAction =
      tripActionLength === 0 ? [defaultActionObject] : [...trip_actions];
    setTripActionsState(initialServerTripAction);
    setTripActionsErrors([]);
    setExpanded(isTripStatesProvided);
    // eslint-disable-next-line
  }, [trip_actions]);

  useEffect(() => {
    if (!tripTypeName) {
      setTripActionsState([]);
      setExpanded(false);
    }
    // eslint-disable-next-line
  }, [setTripActionsState, tripTypeName]);

  const validate = useCallback(
    (name, value, index) => {
      if (name === "action_name") {
        setTripActionsErrors((errors) => {
          const foundName = tripActionsState.find(
            (tState) => tState.action_name === value
          );
          if (!!foundName) {
            errors[index] = { [name]: value };
            return [...errors];
          }

          if (!value || errors[index]?.[name]) {
            delete errors[index]?.[name];
            return errors.filter((error) => toJson(error) !== "{}");
          }

          return errors;
        });
      }

      if (name === "state_pre") {
        const chosenTripActions = tripActionsState.filter(
          (pState) => pState[name] === value
        );

        setExcludeStates((prevExcludeStates) => {
          prevExcludeStates.state_post = chosenTripActions.map(
            (ctAction) => ctAction.state_post
          );
          prevExcludeStates.state_post.push(value);

          if (chosenTripActions.length + 1 === tripStateLength - 1) {
            prevExcludeStates[name].push(value);
          }

          return { ...prevExcludeStates };
        });
      }
    },
    [tripActionsState, tripStateLength]
  );

  const handleChange = useCallback(
    (index: number, name: string) => (e, value) => {
      validate(name, value, index);
      setTripActionsState((prevTripState) => {
        prevTripState[index] = {
          ...prevTripState[index],
          [name]: value,
        };
        return [...prevTripState];
      });
    },
    [validate]
  );

  const handleAddNewLine = () =>
    setTripActionsState((tState) => tState.concat(defaultActionObject));

  const handleRemoveLine = (index: number) => () => {
    setTripActionsErrors((errors) => {
      return errors.filter((_, i) => i !== index);
    });

    setTripActionsState((tState) => {
      return tState.filter((_, i) => i !== index);
    });
  };

  const handleSave = () => {
    createTripActions(
      tripActionsState.map(({ action_roles, ...rest }) => ({
        ...rest,
        tripTypeActionPerms:
          action_roles?.map((action_role) => ({
            action_name: rest.action_name,
            action_role,
          })) || [],
      }))
    ).then(() => {
      onSubmit?.();
    });
  };

  const maximumPossibleActionsCount = tripStateLength * (tripStateLength - 1);

  return (
    <ExpandablePanel
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={tripStateLength === 0}
      title="Trip Actions"
      subTitle="Configuration of actions for trip type"
      actions={
        <Button
          className={classes.button}
          size="md"
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            toJson(tripActionsState) === toJson(trip_actions) ||
            tripActionsState.some((tActions) => {
              return (
                !tActions.action_name?.trim() ||
                !tActions.state_post ||
                !tActions.state_pre
              );
            }) ||
            tripActionsErrors.filter(Boolean).length > 0
          }
        >
          Save
        </Button>
      }
    >
      <Grid container direction="column">
        {tripActionsState.map((tState, index) => {
          const rowNumber = index + 1;
          const isAlreadySavedTripActions = index < tripActionLength;

          return (
            <React.Fragment key={index}>
              <Grid container direction="row">
                <Field
                  classes={{
                    root: classes.inputContainer,
                    input: classes.input,
                  }}
                  required
                  label="State from"
                  variant="autocomplete"
                  // @ts-ignore
                  options={trip_states.map((tState) => tState.trip_state)}
                  value={tState.state_pre}
                  onChange={handleChange(index, "state_pre")}
                  disabled={isAlreadySavedTripActions}
                  autoCompleteConfigs={{
                    filterOptions: (tStates) =>
                      tStates.filter(
                        (tState) => !excludeStates.state_pre.includes(tState)
                      ),
                    disableClearable: true,
                  }}
                />

                <Field
                  classes={{
                    root: classes.inputContainer,
                    input: classes.input,
                  }}
                  required
                  label="State to"
                  variant="autocomplete"
                  // @ts-ignore
                  options={trip_states.map((tState) => tState.trip_state)}
                  value={tState.state_post}
                  onChange={handleChange(index, "state_post")}
                  disabled={isAlreadySavedTripActions}
                  autoCompleteConfigs={{
                    filterOptions: (tStates) =>
                      tStates.filter(
                        (tState) => !excludeStates.state_post.includes(tState)
                      ),
                    disableClearable: true,
                  }}
                />

                <Field
                  classes={{
                    root: classes.inputContainer,
                    input: classes.input,
                  }}
                  required
                  label="Action name"
                  value={tState.action_name}
                  onChange={handleChange(index, "action_name")}
                  disabled={isAlreadySavedTripActions}
                  error={!!tripActionsErrors[index]?.action_name}
                  helperText={
                    !!tripActionsErrors[index]?.action_name
                      ? `Action name: "${tripActionsErrors[index]?.action_name}" already exist`
                      : undefined
                  }
                />

                <Field
                  classes={{
                    root: classes.inputContainer,
                    input: classes.input,
                  }}
                  label="Roles"
                  variant="autocomplete"
                  // @ts-ignore
                  options={action_roles.map((aRole) => aRole.action_role)}
                  value={tState.action_roles || []}
                  multiline
                  onChange={handleChange(index, "action_roles")}
                  autoCompleteConfigs={{
                    filterSelectedOptions: true,
                    disableClearable: true,
                    limitTags: 1,
                    ChipProps: {
                      classes: {
                        root: classes.chip,
                      },
                    },
                    classes: {
                      inputRoot: classes.test,
                    },
                  }}
                />

                <div className={classes.inputContainer}>
                  {index < tripActionLength && (
                    <Link
                      className={classes.link}
                      to={`/settings/action/${tState.action_name}/schema`}
                    >
                      <SquareButton
                        variant="outlined"
                        color="primary"
                        className={classes.buttonPlacement}
                        title="Add schema form"
                      >
                        <TuneIcon />
                      </SquareButton>
                    </Link>
                  )}
                  {index >= tripActionLength && tripActionsState.length !== 1 && (
                    <SquareButton
                      color="secondary"
                      onClick={handleRemoveLine(index)}
                      variant="contained"
                      size="small"
                      className={classes.buttonPlacement}
                    >
                      <DeleteIcon />
                    </SquareButton>
                  )}
                </div>
              </Grid>
              {rowNumber === tripActionsState.length &&
                rowNumber < maximumPossibleActionsCount && (
                  <MuiButton
                    color="primary"
                    onClick={handleAddNewLine}
                    variant="contained"
                    size="small"
                    className={clsx(
                      classes.inlineButton,
                      classes.buttonPlacement
                    )}
                  >
                    <AddIcon />
                    Add line
                  </MuiButton>
                )}
            </React.Fragment>
          );
        })}
      </Grid>
    </ExpandablePanel>
  );
};

export default TripActionsPage;
