import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { UPDATE_TRIP_TYPE } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useUpdateTripType = () => {
  const [update] = useMutation(UPDATE_TRIP_TYPE);
  const withErrorHandling = useErrorHandling();

  const updateTripType = useCallback(
    async (variables: {
      initial_trip_type: string;
      trip_type: string;
      display_name: string;
      description: string;
      start_state: string;
    }) => {
      withErrorHandling(update, {
        variables,
      });
    },
    [update, withErrorHandling]
  );

  return updateTripType;
};

export default useUpdateTripType;
