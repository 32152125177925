import React, { FC, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Button } from "@periplus/ui-library";

interface CustomizeTableDialogProps {
  onClose: () => void;
  onConfirm: (
    result: { name: string; visible: boolean; title: string }[]
  ) => void;
  columns: { name: string; visible: boolean; title: string }[];
}

const CustomizeTableDialog: FC<CustomizeTableDialogProps> = ({
  onClose,
  onConfirm,
  columns,
}) => {
  const [innerColumns, setInnerColumns] = useState(columns);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Customization</DialogTitle>
      <DialogContent>
        {
          <table style={{ width: 400 }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Column</th>
                <th style={{ textAlign: "left" }}>Visible</th>
              </tr>
            </thead>
            <tbody>
              {innerColumns.map((innerColumn) => (
                <tr key={innerColumn.name}>
                  <td>{innerColumn.title}</td>
                  <td>
                    <Checkbox
                      checked={innerColumn.visible}
                      onChange={() =>
                        setInnerColumns((prevInnerColumns) =>
                          prevInnerColumns.map((prevInnerColumn) =>
                            prevInnerColumn.name === innerColumn.name
                              ? {
                                  ...prevInnerColumn,
                                  visible: !prevInnerColumn.visible,
                                }
                              : prevInnerColumn
                          )
                        )
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="lg">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(innerColumns)}
          variant="contained"
          color="primary"
          size="lg"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizeTableDialog;
