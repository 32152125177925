import React, { FC, useCallback, useState } from "react";
import { Box, IconButton, Tab, Tabs } from "@material-ui/core";
import { Loader, ActionBar, ActionBarItem, Button } from "@periplus/ui-library";
import { useGetTrips } from "graphql/trips";
import { useParams } from "react-router-dom";
import useTripStyles from "./Trip.styles";
import {
  TripMap,
  TripActions,
  Diagram,
  RemoveLastActionDialog,
} from "./components";
import { Link } from "react-router-dom";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import useDeleteTripAction from "graphql/trips/useDeleteTripAction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Trip: FC = () => {
  const { trip_id } = useParams() as { trip_id: string };
  const [tabIndex, setTabIndex] = React.useState(0);
  const [mapFocus, setMapFocus] = useState<[number, number] | undefined>();

  const [
    isOpenRemoveLastActionDialog,
    setIsOpenRemoveLastActionDialog,
  ] = useState(false);

  const {
    data: {
      trips: [trip],
    },
    loading: tripLoading,
    refetch: tripsRefetch,
  } = useGetTrips({ trip_id });
  const deleteTripAction = useDeleteTripAction();

  const handleConfirmRemoveLastAction = useCallback(async () => {
    await deleteTripAction({
      trip_id: trip?.trip_id,
      action_name: trip?.edit_action?.action,
    });
    await new Promise((resolve) => setTimeout(resolve, 500)); //TODO
    await tripsRefetch();
    setIsOpenRemoveLastActionDialog(false);
  }, [deleteTripAction, trip, tripsRefetch]);

  const handleChangeMapFocus = useCallback(
    (coord: [number, number]) => setMapFocus(coord),
    []
  );

  const classes = useTripStyles();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box display="flex">
      {tripLoading ? (
        <Loader />
      ) : (
        <>
          {isOpenRemoveLastActionDialog && (
            <RemoveLastActionDialog
              onClose={() => setIsOpenRemoveLastActionDialog(false)}
              onConfirm={handleConfirmRemoveLastAction}
            />
          )}
          <Box className={classes.leftColumn}>
            <ActionBar justify="flex-end">
              <ActionBarItem>
                {trip.available_actions?.map((available_action) => (
                  <Link
                    className={classes.tripAction}
                    to={`/trips/${trip.trip_id}/new-action?trip_action=${available_action.action}`}
                    key={available_action.action}
                  >
                    <Button variant="contained" color="primary" size="md">
                      {available_action.display_name}
                    </Button>
                  </Link>
                ))}
                {trip.edit_action?.action && (
                  <>
                    <IconButton
                      className={clsx(
                        classes.tripAction,
                        classes.tripActionIcon
                      )}
                      title="Cancel last action"
                      onClick={() => setIsOpenRemoveLastActionDialog(true)}
                    >
                      <SettingsBackupRestoreIcon
                        className={classes.tripActionIconButton}
                      />
                    </IconButton>
                    <Link
                      className={classes.tripAction}
                      to={`/trips/${trip.trip_id}/edit`}
                      title="Edit"
                    >
                      <IconButton className={classes.tripActionIcon}>
                        <EditOutlinedIcon
                          className={classes.tripActionIconButton}
                        />
                      </IconButton>
                    </Link>
                  </>
                )}
              </ActionBarItem>
            </ActionBar>
            <TripActions
              tripActions={trip.tripActions}
              onChangeMapFocus={handleChangeMapFocus}
            />
          </Box>
          <Box className={classes.rightColumn}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
            >
              <Tab label="Map" />
              <Tab label="Graph" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Box className={classes.tripMap}>
                <TripMap trip={trip} focus={mapFocus} />
              </Box>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Box className={classes.tripMermaid}>
                <Diagram
                  tripActions={trip.tripActions}
                  diagram={trip.tripType.state_diagram}
                  activeStep={trip.state_description}
                />
              </Box>
            </TabPanel>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Trip;
