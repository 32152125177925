import React, { FC } from "react";
import { Box, Paper } from "@material-ui/core";
import { Title, Button } from "@periplus/ui-library";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { ITripAvailableAction } from "graphql/trips/types";

interface TripDataProps {
  className: string;
  trip_id: string;
  trip_type: string;
  available_actions: ITripAvailableAction[];
  tripData: { field: string; value: any }[];
}

const useStyles = makeStyles({
  tripTitleContainer: {
    padding: 16,
  },
  tripTitle: {
    color: "#737A96",
    fontWeight: 600,
  },
  tripAction: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    "&:first-child": {
      marginLeft: "auto",
    },
    "&:not(:first-child)": {
      marginLeft: 10,
    },
  },
  tripDataContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    flexWrap: "wrap",
    borderTop: "1px solid #e1e1e1",
  },
  tripData: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  tripDataLabel: {
    color: "#C8C8D4",
    fontSize: 14,
    fontWeight: 600,
  },
});

const TripData: FC<TripDataProps> = ({
  className,
  trip_id,
  trip_type,
  available_actions,
  tripData,
}) => {
  const classes = useStyles();
  return (
    <Paper className={className}>
      <Box
        className={classes.tripTitleContainer}
        display="flex"
        justifyContent="space-between"
      >
        <Title
          subTitle="All information regarding this shipment"
          classes={{
            title: classes.tripTitle,
          }}
        >
          {trip_id}
        </Title>
        <Box>
          {available_actions?.map((available_action) => (
            <Link
              className={classes.tripAction}
              to={`/trips/action?typeId=${trip_type}&actionId=${available_action.action}&tripId=${trip_id}`}
              key={available_action.action}
            >
              <Button variant="contained" color="primary" size="md">
                {available_action.display_name}
              </Button>
            </Link>
          ))}
        </Box>
      </Box>
      {!!tripData.length && (
        <Box
          className={classes.tripDataContainer}
          display="flex"
          flexDirection="column"
        >
          <table style={{ padding: "8px 16px", width: "fit-content" }}>
            {tripData.map((tripDataItem) => (
              <tr key={tripDataItem.field}>
                <td className={classes.tripDataLabel}>{tripDataItem.field}</td>
                <td style={{ paddingLeft: 15 }}>
                  {JSON.stringify(tripDataItem.value)}
                </td>
              </tr>
            ))}
          </table>
        </Box>
      )}
    </Paper>
  );
};

export default TripData;
