import React, { useState, ReactType, useMemo, useCallback } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";

type Item = {
  id: number;
  action: () => void;
  Text: ReactType | string;
};

interface ISimpleMenuProps {
  toggleMenu: (arg0: boolean) => void;
  elements: Item[];
  renderButton: (args: { onClick: (event?: any) => void }) => void;
  anchorPlacement?: PopoverOrigin;
  menuPlacement?: PopoverOrigin;
  getContentAnchorEl?: any;
  initialElementId?: Item;
  [key: string]: any;
}

const SimpleMenu = ({
  elements,
  renderButton,
  toggleMenu,
  getContentAnchorEl = null,
  anchorPlacement = {
    vertical: "bottom",
    horizontal: "right",
  },
  menuPlacement = {
    vertical: "top",
    horizontal: "right",
  },
}: ISimpleMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    toggleMenu(false);
    setAnchorEl(null);
  }, [setAnchorEl, toggleMenu]);

  const changeItem = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );

  const menuItems = useMemo(
    () =>
      elements.map(({ id, Text, action }) => (
        <MenuItem key={id.toString()} onClick={changeItem(action)}>
          {Text}
        </MenuItem>
      )),
    [changeItem, elements]
  );

  return (
    <div>
      {renderButton({ onClick: handleClick })}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={anchorPlacement}
        transformOrigin={menuPlacement}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={getContentAnchorEl}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
