import { useMemo } from "react";
import { useQuery, OperationVariables } from "react-apollo";
import { GET_ACTION_ROLE } from "./queries";
import { IActionRole } from "./types";

const useGetActionRoles = (options: OperationVariables = {}) => {
  const result = useQuery<{ action_role: IActionRole[] }>(
    GET_ACTION_ROLE,
    options
  );

  return useMemo(
    () => ({
      ...result,
      data: {
        action_roles: result.data?.action_role || [],
      },
    }),
    [result]
  );
};

export default useGetActionRoles;
