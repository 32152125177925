import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  capitalize,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Button } from "@periplus/ui-library";
import { pick, omit } from "lodash";

import Field from "components/Field";
import ExpandablePanel from "components/ExpandablePanel";
import {
  useCreateTripType,
  useUpdateTripType,
  ITripType,
} from "graphql/tripType";
import { ITripState } from "graphql/tripState";
import { toJson } from "utils/json";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  input: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface TripTypeProps {
  onSubmit?: (tripTypeName: string) => void;
  trip_type?: ITripType;
  trip_states: ITripState[];
}

const TripTypePage: FC<TripTypeProps> = ({
  onSubmit,
  trip_type,
  trip_states,
}) => {
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();
  const classes = useStyles();
  const defaultState = {
    trip_type: "",
    display_name: "",
    description: "",
    start_state: "",
  };

  const createTripType = useCreateTripType();
  const updateTripType = useUpdateTripType();

  const serverTripType = useMemo(
    () =>
      Object.assign(
        defaultState,
        pick(trip_type || {}, Object.keys(defaultState))
      ),
    // eslint-disable-next-line
    [trip_type]
  );

  const [tripTypeState, setTripTypeState] = useState<{
    trip_type: string;
    display_name: string;
    description: string;
    start_state: string;
  }>(serverTripType);
  const [initialTripTypeState, setInitialTripTypeState] = useState<
    Partial<ITripType>
  >(serverTripType);

  useEffect(() => {
    if (!!tripTypeName) {
      setTripTypeState(serverTripType);
      setInitialTripTypeState(serverTripType);
    } else {
      setTripTypeState(defaultState);
      setInitialTripTypeState(defaultState);
    }
    // eslint-disable-next-line
  }, [tripTypeName, serverTripType]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.persist();
      setTripTypeState((prevTripType) => ({
        ...prevTripType,
        [e.target.name]: e.target.value,
      }));
    },
    []
  );

  const handleUpdateAfterSave = () => {
    setInitialTripTypeState(tripTypeState);
    onSubmit && onSubmit(tripTypeState.trip_type);
  };

  const handleSave = () => {
    if (!tripTypeName) {
      createTripType(omit(tripTypeState, "start_state")).then(
        handleUpdateAfterSave
      );
      return;
    }
    updateTripType({
      ...tripTypeState,
      initial_trip_type: tripTypeState.trip_type,
    }).then(handleUpdateAfterSave);
  };

  return (
    <ExpandablePanel
      defaultExpanded
      title="Trip Type"
      subTitle="Configuration of trip type"
      actions={
        <Button
          className={classes.button}
          size="md"
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            toJson(tripTypeState) === toJson(initialTripTypeState) ||
            !tripTypeState.trip_type?.trim() ||
            !tripTypeState.display_name?.trim()
          }
        >
          Save
        </Button>
      }
    >
      {!tripTypeName && (
        <Field
          classes={{
            root: classes.inputContainer,
            input: classes.input,
          }}
          label="Name"
          name="trip_type"
          required
          value={tripTypeState.trip_type}
          onChange={handleChange}
        />
      )}

      <Field
        classes={{
          root: classes.inputContainer,
          input: classes.input,
        }}
        label="Display Name"
        name="display_name"
        required
        value={tripTypeState.display_name}
        onChange={handleChange}
      />

      <Field
        classes={{
          root: classes.inputContainer,
          input: classes.input,
        }}
        label="Description"
        name="description"
        value={tripTypeState.description}
        onChange={handleChange}
      />

      {trip_states?.length > 0 && (
        <FormControl className={classes.inputContainer}>
          <InputLabel shrink htmlFor="start_state">
            Start State
          </InputLabel>
          <TextField
            placeholder="Start State"
            name="start_state"
            variant="outlined"
            className={classes.input}
            size="small"
            select
            value={tripTypeState.start_state}
            onChange={handleChange}
            SelectProps={{
              MenuProps: {
                variant: "menu",
              },
            }}
          >
            {trip_states.map((tState) => (
              <MenuItem value={tState.trip_state} key={tState.trip_state}>
                {`${capitalize(tState.display_name)} (${tState.description})`}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
    </ExpandablePanel>
  );
};

export default TripTypePage;
