import React, { FC } from "react";
import { Button, Dialog } from "@periplus/ui-library";

interface RemoveLastActionDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

const RemoveLastActionDialog: FC<RemoveLastActionDialogProps> = ({
  onClose,
  onConfirm,
}) => (
  <Dialog
    open={true}
    onClose={onClose}
    variant="warning"
    actions={
      <>
        <Button variant="contained" size="md" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="md"
          color="primary"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </>
    }
  >
    Are you sure you want to remove last action?
  </Dialog>
);

export default RemoveLastActionDialog;
