import React, { FC } from "react";
import { makeStyles, Box } from "@material-ui/core";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { TripForm } from "./components";
import { useGetTripTypeActions } from "graphql/tripTypeAction";
import { Loader } from "@periplus/ui-library";

const useStyles = makeStyles({
  root: {
    paddingLeft: 180,
    paddingRight: 180,
  },
});

const TripStartAction: FC = () => {
  const { urlSearchParams } = useUrlSearchParams();
  const classes = useStyles();

  const {
    data: {
      trip_actions: [trip_type_action],
    },
    loading: tripTypesActionsLoading,
  } = useGetTripTypeActions({
    trip_type: urlSearchParams.trip_type,
    action_name: urlSearchParams.trip_action,
  });

  return (
    <Box className={classes.root}>
      {tripTypesActionsLoading ? (
        <Loader />
      ) : (
        <TripForm
          trip_type={urlSearchParams.trip_type}
          trip_action={urlSearchParams.trip_action}
          schema={trip_type_action.params_schema}
          uiSchema={trip_type_action.ui_schema}
        />
      )}
    </Box>
  );
};

export default TripStartAction;
