import { useMemo } from "react";
import { useQuery, OperationVariables } from "react-apollo";
import { GET_TRIP_STATE_COORDS } from "./queries";
import { ITripTypeStateCoordV } from "../tripTypeStateCoord/types";

const useGetTripStateCoords = (
  variables: { trip_type?: string; trip_state?: string } = {},
  options: OperationVariables = {}
) => {
  const result = useQuery<{ trip_type_state_coord: ITripTypeStateCoordV[] }>(
    GET_TRIP_STATE_COORDS,
    {
      variables,
      ...options,
    }
  );

  return useMemo(
    () => ({
      ...result,
      data: { trip_state_coords: result.data?.trip_type_state_coord || [] },
    }),
    [result]
  );
};

export default useGetTripStateCoords;
