import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { CREATE_TRIP_STATE } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useCreateTripState = () => {
  const [upsert] = useMutation(CREATE_TRIP_STATE);
  const withErrorHandling = useErrorHandling();

  const upsertTripType = useCallback(
    async (
      tripStates: {
        trip_type: string;
        trip_state: string;
        display_name: string;
        description: string;
      }[]
    ) => {
      withErrorHandling(upsert, {
        variables: {
          tripStates,
        },
      });
    },
    [upsert, withErrorHandling]
  );

  return upsertTripType;
};

export default useCreateTripState;
