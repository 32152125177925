import React, { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogProps,
} from "@material-ui/core";
import DialogTitle from "./DialogTitle";
import { Button } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";

interface IFormDialog extends DialogProps {
  titleText: string;
  subTitleText?: string;
  onClose(): void;
  onConfirm(): void;
  isConfirmDisabled: boolean;
}

const FormDialog: FC<IFormDialog> = ({
  titleText,
  subTitleText,
  onClose,
  onConfirm,
  isConfirmDisabled,
  children,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} {...other}>
      <DialogTitle title={titleText} subTitle={subTitleText} />
      <DialogContent>{children}</DialogContent>
      <DialogActions
        style={{
          padding: 15,
        }}
      >
        <Button onClick={onClose} style={{ color: "grey" }}>
          {t("common:abort")}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isConfirmDisabled}
          variant="contained"
          // color="primary"
        >
          {t("common:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
