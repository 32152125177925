import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import { ControlledEditor as MonacoEditor } from "@monaco-editor/react";
import clsx from "clsx";

import { Close, Check } from "@material-ui/icons";
import { Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  editor: {
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#d2d2d2",
      zIndex: 2,
      opacity: "0.3",
      pointerEvents: "none",
    },
    "& textarea": {
      display: "none",
    },
  },
});

interface EditorProps {
  title: string;
  onChange?: (code: string) => void;
  code?: string;
  height?: number;
  readonly?: boolean;
}

const Editor: FC<EditorProps> = ({
  title,
  onChange,
  code,
  height = 400,
  readonly = false,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({ valid: true, code });

  const handleCodeChange = useCallback(
    (ev, code) => {
      try {
        const parsedCode = JSON.parse(code);
        setState({ valid: true, code });
        onChange?.(parsedCode);
      } catch (err) {
        setState({ valid: false, code });
      }
    },
    [onChange]
  );

  useEffect(() => {
    setState({ valid: true, code });
  }, [code]);

  const icon = useMemo(
    () =>
      state.valid ? (
        <Check color="secondary" fontSize="small" />
      ) : (
        <Close color="error" fontSize="small" />
      ),
    [state.valid]
  );

  return (
    <Grid container direction="column" style={{ position: "relative" }}>
      <Grid container direction="row" alignContent="center">
        {icon} <Typography>{" " + title}</Typography>
      </Grid>
      <MonacoEditor
        className={clsx({ [classes.editor]: readonly })}
        language="json"
        value={state.code}
        theme="vs-light"
        onChange={handleCodeChange}
        height={height}
        options={{
          minimap: {
            enabled: false,
          },
          automaticLayout: true,
          readOnly: readonly,
          disabled: true,
        }}
      />
    </Grid>
  );
};

export default Editor;
