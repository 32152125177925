import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useErrorHandling = <T = any>() => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const withErrorHandling = useCallback(
    async (callback, ...args) => {
      try {
        const result = (await callback.apply(callback, args)) as T;
        return Promise.resolve(result);
      } catch (e) {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
        return Promise.reject(e);
      }
    },
    [enqueueSnackbar, t]
  );

  return withErrorHandling;
};

export default useErrorHandling;
