import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Paper,
  Collapse,
  Box,
  makeStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ITripActionV } from "graphql/tripAction";
import { Typography } from "@periplus/ui-library";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Slider from "./components/Slider";
import moment from "moment-timezone";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import clsx from "clsx";

const useStyle = makeStyles({
  panelHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 16px",
  },
  panelHeaderTitleContainer: {},
  panelHeaderTitle: {},
  panelHeaderDate: {},
  panelHeaderLocation: {},
  panelHeaderControlContainer: {},
  panelBody: {
    borderTop: "1px solid #e1e1e1",
    padding: "8px 16px",
  },
  data: { width: "100%" },
  dataField: {},
  dataFieldLabel: {
    verticalAlign: "top",
    width: "1%",
    whiteSpace: "nowrap",
    fontSize: 10,
    lineHeight: "10px",
    fontWeight: 400,
    letterSpacing: 0.25,
    color: "#737A96",
    textTransform: "uppercase",
  },
  dataFieldValue: {
    paddingLeft: 15,
  },
  actionPropsTable: {
    width: "unset",
  },
  actionPropsTableCell: {
    paddingLeft: 0,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  actionPropsTableCellHead: {
    paddingTop: 0,
    fontSize: 10,
    lineHeight: "unset",
    fontWeight: 400,
    letterSpacing: 0.25,
    color: "#737A96",
    textTransform: "uppercase",
  },
});

interface TripActionProps {
  className: string;
  tripAction: ITripActionV;
  initialIsExpanded: boolean;
  onChangeMapFocus: (coord: [number, number]) => void;
}

const TripAction: FC<TripActionProps> = ({
  className,
  tripAction,
  initialIsExpanded,
  onChangeMapFocus,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);

  const classes = useStyle();

  const handleChangeExpanded = useCallback(
    () => setIsExpanded((prev) => !prev),
    []
  );

  const sortedActionParamsKeys = useMemo(() => {
    const actionParamsKeys = Object.keys(tripAction.action_params);
    const simpleTypeActionParamsKeys = actionParamsKeys.filter(
      (actionParamsKey) => {
        const actionParamProps = tripAction.tripTypeAction.params_schema
          .properties?.[actionParamsKey] as any;
        const tripActionParamsType = actionParamProps?.type;
        const tripActionParamsFormat = actionParamProps?.format;
        return (
          tripActionParamsType !== "object" &&
          tripActionParamsType !== "array" &&
          tripActionParamsFormat !== "data-url"
        );
      }
    );
    const referenceTypeActionParamsKeys = actionParamsKeys.filter(
      (actionParamsKey) => {
        const tripActionParamsType = (tripAction.tripTypeAction.params_schema
          .properties?.[actionParamsKey] as any)?.type;
        return (
          tripActionParamsType === "object" || tripActionParamsType === "array"
        );
      }
    );
    const documentsTypeActionParamsKeys = actionParamsKeys.filter(
      (actionParamsKey) => {
        const tripActionParamsFormat = (tripAction.tripTypeAction.params_schema
          .properties?.[actionParamsKey] as any)?.format;
        return tripActionParamsFormat === "data-url";
      }
    );

    return [
      ...simpleTypeActionParamsKeys,
      ...referenceTypeActionParamsKeys,
      ...documentsTypeActionParamsKeys,
    ];
  }, [
    tripAction.action_params,
    tripAction.tripTypeAction.params_schema.properties,
  ]);
  console.log(tripAction);
  return (
    <Paper className={className}>
      <Box className={classes.panelHeader} onClick={handleChangeExpanded}>
        <Box className={classes.panelHeaderTitleContainer}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.panelHeaderTitle}>
              {tripAction.tripTypeState.display_name}
            </Typography>
            {tripAction.tripTypeState.tripTypeStateCoord && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeMapFocus(
                    tripAction.tripTypeState.tripTypeStateCoord.coords
                      .coordinates
                  );
                }}
              >
                <LocationOnOutlinedIcon />
              </IconButton>
            )}
          </Box>
          <Typography variant="subTitle">
            {moment(tripAction.created).format("MM.DD.YYYY")}
          </Typography>
        </Box>
        <Box className={classes.panelHeaderControlContainer}>
          <IconButton size="small">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isExpanded}>
        <Box className={classes.panelBody}>
          <table className={classes.data}>
            <tbody>
              {sortedActionParamsKeys.map((actionParamsKey) => {
                const tripActionParam =
                  tripAction.action_params[actionParamsKey];
                const tripActionParamSchema = tripAction.tripTypeAction
                  .params_schema.properties?.[actionParamsKey] as any;
                const tripActionDocument = tripAction.tripActionDocuments.find(
                  (el) => el.param_name === actionParamsKey
                );
                return (
                  <tr className={classes.dataField} key={actionParamsKey}>
                    <td className={classes.dataFieldLabel}>
                      {tripActionParamSchema?.title || actionParamsKey}
                    </td>
                    <td className={classes.dataFieldValue}>
                      {(() => {
                        if (tripActionParamSchema?.format) {
                          switch (tripActionParamSchema.format) {
                            case "data-url":
                              return (
                                tripActionDocument && (
                                  <Slider
                                    tripActionDocument={tripActionDocument}
                                  />
                                )
                              );
                            case "date-time":
                              return moment(tripActionParam).format(
                                "MM.DD.YYYY hh:mm:ss"
                              );
                            case "date":
                              return moment(tripActionParam).format(
                                "MM.DD.YYYY"
                              );
                            default:
                              return JSON.stringify(tripActionParam);
                          }
                        } else {
                          switch (tripActionParamSchema?.type) {
                            case "string":
                            case "number":
                            case "boolean":
                              return tripActionParam.toString();
                            case "object":
                              const objectType =
                                tripActionParamSchema?.properties;
                              const objectTypeKeys = Object.keys(objectType);

                              return (
                                <Table
                                  className={classes.actionPropsTable}
                                  size="small"
                                >
                                  <TableBody>
                                    {objectTypeKeys.map((objectTypeKey) => (
                                      <TableRow key={objectTypeKey}>
                                        <TableCell
                                          className={clsx(
                                            classes.actionPropsTableCell,
                                            classes.actionPropsTableCellHead
                                          )}
                                        >
                                          {objectType[objectTypeKey].title}
                                        </TableCell>
                                        <TableCell
                                          className={
                                            classes.actionPropsTableCell
                                          }
                                        >
                                          {(() => {
                                            switch (
                                              objectType[objectTypeKey].format
                                            ) {
                                              case "datetime":
                                                return moment(
                                                  tripActionParam[objectTypeKey]
                                                ).format("MM.DD.YYYY hh:mm:ss");
                                              case "date":
                                                return moment(
                                                  tripActionParam[objectTypeKey]
                                                ).format("MM.DD.YYYY");
                                              default:
                                                return tripActionParam[
                                                  objectTypeKey
                                                ]?.toString();
                                            }
                                          })()}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              );

                            case "array":
                              if (!tripActionParam.length) return;

                              const arrayType = (tripAction.tripTypeAction
                                .params_schema.definitions?.[
                                tripActionParamSchema.items.$ref
                                  ?.split("/")
                                  .pop()
                              ] as any)?.properties;

                              if (!arrayType) return tripActionParam.join(", ");

                              const arrayTypeKeys = Object.keys(arrayType);
                              return (
                                <Table
                                  className={classes.actionPropsTable}
                                  size="small"
                                >
                                  <TableHead>
                                    <TableRow>
                                      {arrayTypeKeys.map((arrayTypeKey) => (
                                        <TableCell
                                          className={clsx(
                                            classes.actionPropsTableCellHead,
                                            classes.actionPropsTableCell
                                          )}
                                          key={arrayTypeKey}
                                        >
                                          {arrayType[arrayTypeKey].title}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {tripActionParam.map(
                                      (tripActionParamItem, i) => (
                                        <TableRow key={i}>
                                          {arrayTypeKeys.map((arrayTypeKey) => (
                                            <TableCell
                                              className={
                                                classes.actionPropsTableCell
                                              }
                                              key={arrayTypeKey}
                                            >
                                              {(() => {
                                                switch (
                                                  arrayType[arrayTypeKey].format
                                                ) {
                                                  case "datetime":
                                                    return moment(
                                                      tripActionParamItem[
                                                        arrayTypeKey
                                                      ]
                                                    ).format(
                                                      "MM.DD.YYYY hh:mm:ss"
                                                    );
                                                  case "date":
                                                    return moment(
                                                      tripActionParamItem[
                                                        arrayTypeKey
                                                      ]
                                                    ).format("MM.DD.YYYY");
                                                  default:
                                                    return tripActionParamItem[
                                                      arrayTypeKey
                                                    ]?.toString();
                                                }
                                              })()}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              );
                            default:
                              return JSON.stringify(tripActionParam);
                          }
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default TripAction;
