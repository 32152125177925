export const validateEmail = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const displayValidUsername = (user: string) => {
  if (validateEmail(user)) {
    const [userFullName] = user.split("@");
    return userFullName.split(".").map((text) => text.toLowerCase());
  }
  return user.split(" ").map((text) => text.toLowerCase());
};
