import gql from "graphql-tag";

export const GET_ACTION_ROLE = gql`
  query GET_ACTION_ROLE {
    action_role {
      action_role
      role_description
    }
  }
`;
