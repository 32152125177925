import client, { keycloak } from "graphql/client";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { KeycloakProvider } from "@react-keycloak/web";
import { KeycloakInitOptions } from "keycloak-js";
import Cookies from "js-cookie";
import { AuthProvider } from "keycloak/context/AuthContext";
import { ApolloProvider } from "react-apollo";
import { SnackbarProvider } from 'notistack';

const onEvent = (event: any, error: any) => {
  if (typeof error !== undefined) {
    switch (event) {
      case "onAuthSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshError":
        console.log("onAuthRefreshError");
        break;
      case "onAuthError":
        console.log("onAuthError");
        console.log(event);
        console.log(error);
        break;
      default:
        break;
    }
  }
};

const refreshToken = Cookies.get("refreshToken");
const token = Cookies.get("token");
const idToken = Cookies.get("idToken");

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  //@ts-ignore
  promiseType: "legacy",
  // @ts-ignore
  token: token,
  // @ts-ignore
  refreshToken: refreshToken,
  // @ts-ignore
  idToken: idToken,
};

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={keycloakProviderInitConfig}
    onEvent={onEvent}
  >
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
      <ApolloProvider client={client}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </KeycloakProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
