import React, { FC } from "react";
import {
  Typography,
  DialogTitleProps,
  DialogTitle as MuiDialogTitle,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

interface IDialogTitle extends DialogTitleProps {
  title: string;
  subTitle?: string;
}

const DialogTitle: FC<IDialogTitle> = ({ title, subTitle }) => {
  const theme = useTheme();

  return (
    <MuiDialogTitle disableTypography>
      <Typography
        variant="h6"
        style={{
          color: theme.palette.primary.main,
          fontSize: 18,
          lineHeight: "20px",
        }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.primary.dark,
            fontSize: 14,
            lineHeight: "20px",
          }}
        >
          {subTitle}
        </Typography>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
