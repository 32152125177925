import React, { FC, useEffect, useState } from "react";
import { makeStyles, Box, Divider } from "@material-ui/core";
import SliderPreview from "./SliderPreview";
import SliderThumbnail from "./SliderThumbnail";
import { Slider as PuiSlider, withSlidePreviewer } from "@periplus/ui-library";
import { ITripDocumentV } from "graphql/tripActionDocuments/types";
import { downloadBlob } from "utils/azureBlobStorageUtils";
import PrintButton from "./PrintButton";
import DownloadButton from "./DownloadButton";
import ShareButton from "./ShareButton";
import { useAuth } from "keycloak/context/AuthContext";

const SliderWithPreviewer = withSlidePreviewer(PuiSlider);

const useStyles = makeStyles({
  sliderContainer: {
    display: "flex",
  },
  slider: {
    flexGrow: 1,
    flex: "auto",
    position: "relative",
    height: 164,
    marginRight: 18,
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    minWidth: 30,
  },
  divider: {
    height: 75,
  },
  controlIcon: {
    color: "#757575",
    cursor: "pointer",
    "&:not(:first-child)": {
      marginTop: 6,
    },
  },
});

interface SliderProps {
  tripActionDocument: ITripDocumentV;
}

const Slider: FC<SliderProps> = ({ tripActionDocument }) => {
  const classes = useStyles();
  const { idToken } = useAuth();
  const [tripDocumentThumbnails, setTripDocumentThumbnails] = useState<
    string[]
  >([]);

  useEffect(() => {
    const tripDocumentPartUrl = tripActionDocument.url
      .split(".")
      .slice(0, -1)
      .join(".");
    const tripDocumentsPromises = [] as Promise<string>[];
    for (let i = 1; i <= tripActionDocument.pages_qty; i++) {
      tripDocumentsPromises.push(
        downloadBlob(
          `${tripDocumentPartUrl}-${i.toString().padStart(3, "0")}.jpg`,
          idToken
        )
      );
    }
    Promise.all(tripDocumentsPromises).then((result) =>
      setTripDocumentThumbnails(result)
    );
  }, [tripActionDocument.pages_qty, tripActionDocument.url, idToken]);

  return (
    <Box className={classes.sliderContainer}>
      <Box className={classes.slider}>
        <SliderWithPreviewer
          itemWidth={145}
          itemsSpacing={0}
          itemWidthSpacing={0}
          containerSpacing={0}
          edgeFriction={0.35}
          slidesData={tripDocumentThumbnails}
          renderPreview={SliderPreview}
          hideContainer
        >
          {SliderThumbnail}
        </SliderWithPreviewer>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box className={classes.controls}>
        <DownloadButton
          className={classes.controlIcon}
          url={tripActionDocument.url}
        />
        <ShareButton
          className={classes.controlIcon}
          url={tripActionDocument.url}
        />
        <PrintButton
          className={classes.controlIcon}
          url={tripActionDocument.url}
        />
      </Box>
    </Box>
  );
};

export default Slider;
