import gql from "graphql-tag";

export const GET_USER = gql`
  query GET_USER($id: uuid) {
    users(where: { id: { _eq: $id } }) {
      display_name
      actions
      org_id
      org_logo
      roles
    }
  }
`;
