import React, { FC } from "react";
import { Loader } from "@periplus/ui-library";
import { makeStyles, Box } from "@material-ui/core";
import { TripForm } from "./components";
import { useParams } from "react-router-dom";
import useGetTrips from "graphql/trips/useGetTrips";

const useStyles = makeStyles({
  root: {
    paddingLeft: 180,
    paddingRight: 180,
  },
});

const TripEditAction: FC = () => {
  const { trip_id } = useParams<{ trip_id: string }>();
  const classes = useStyles();

  const {
    data: {
      trips: [trip],
    },
    loading: tripLoading,
  } = useGetTrips({ trip_id });

  return (
    <Box className={classes.root}>
      {tripLoading ? (
        <Loader />
      ) : (
        <TripForm
          trip_id={trip_id}
          trip_type={trip.trip_type}
          trip_action={trip?.edit_action?.action}
          actionData={trip?.edit_action?.form_data}
          schema={trip?.edit_action.params_schema}
          uiSchema={trip?.edit_action.ui_schema}
        />
      )}
    </Box>
  );
};

export default TripEditAction;
