import { useMemo } from "react";
import { OperationVariables, useQuery } from "react-apollo";
import { GET_TRIP_TYPE_ACTIONS } from "./queries";
import { ITripTypeActionV } from "./types";

const useGetTripTypeActions = (
  variables: { trip_type?: string; action_name?: string },
  options: OperationVariables = {}
) => {
  const result = useQuery<{ trip_type_actions: ITripTypeActionV[] }>(
    GET_TRIP_TYPE_ACTIONS,
    {
      variables,
      ...options,
    }
  );

  return useMemo(
    () => ({
      ...result,
      data: { trip_actions: result.data?.trip_type_actions || [] },
    }),
    [result]
  );
};

export default useGetTripTypeActions;
