import React, {useCallback, useMemo} from "react";
import { Grid, MenuItem } from "@material-ui/core";
import FormDialog from "components/Dialog/FormDialog";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from "graphql/mutations/organization";
import { FormSelect, FormTextField } from "@periplus/ui-library";

interface IOrganizationFormDialogProps {
  organization: any;
  org_type: any[];
  onClose(): void;
  refetch(): void;
  onConfirm(): void;
}
const OrganizationDialog: React.FC<IOrganizationFormDialogProps> = ({
  organization,
  org_type,
  onClose,
  refetch,
  onConfirm,}) => {
    const { t } = useTranslation()
    const [ createOrganization ] = useMutation(CREATE_ORGANIZATION);
    const [ updateOrganization ] = useMutation(UPDATE_ORGANIZATION);

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = useMemo(() => {
      return organization || {
        org_name: "",
        org_type: "",
        meta: {
          address: "",
          addresssupplement1: "",
          addresssupplement2: "",
          addresssupplement3: "",
          street: "",
          postalcode:"",
          city:"",
          country: "",
          traderidentificationnumber: ""
        }
      }
    }, [organization])

    const validationSchema = useMemo(() => {
      return Yup.object().shape({
        org_name: Yup.string()
          .trim()
          .required(t("validation:isRequired"))
          .max(100, t("validation:maxLength", { maxLength: 100 })),
        org_type: Yup.string()
          .trim()
          .required(t("validation:isRequired"))
          .max(100, t("validation:maxLength", { maxLength: 100 })),
        meta: Yup.object().shape({
          address: Yup.string()
          .trim()
          .required(t("validation:isRequired"))
          .max(100, t("validation:maxLength", { maxLength: 100 })),
          addresssupplement1: Yup.string()
            .trim()
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          addresssupplement2: Yup.string()
            .trim()
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          addresssupplement3: Yup.string()
            .trim()
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          street: Yup.string()
            .trim()
            .required(t("validation:isRequired"))
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          postalcode: Yup.string()
            .trim()
            .required(t("validation:isRequired"))
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          city: Yup.string()
            .trim()
            .required(t("validation:isRequired"))
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          country: Yup.string()
            .trim()
            .required(t("validation:isRequired"))
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          traderidentificationnumber: Yup.string()
            .trim()
            .required(t("validation:isRequired"))
            .max(100, t("validation:maxLength", { maxLength: 100 })),
          })
      });
    }, [t]);

    const handleSubmit = useCallback(
      (values, { setSubmitting }) => {
        const castValues = validationSchema.cast(values);
        const onCompleted = () => {
          onConfirm();
          setSubmitting(false);
          enqueueSnackbar(t(organization ? "common:updateOrganizationSuccess" : "common:createOrganizationSuccess"), {
            variant: "success",
          });
          refetch();
          onClose();
        };
        const onError = () => {
          setSubmitting(false);
          enqueueSnackbar(t("error:serverError"), { variant: "error" });
        };
        if (organization) {
          delete castValues.__typename;
          updateOrganization({
            variables: {
              ...castValues,
            },
          }).then(onCompleted, onError);
        } else {
          createOrganization({
            variables: {
              ...castValues,
            },
          }).then(onCompleted, onError);
        }
      },
      [
        createOrganization,
        enqueueSnackbar,
        onClose,
        onConfirm,
        organization,
        refetch,
        t,
        updateOrganization,
        validationSchema
      ]
    );

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({submitForm, isSubmitting}) => (
           <FormDialog
           open
           fullWidth
           maxWidth="sm"
           titleText={
             organization
               ? `${t("common:edit")}: ${organization.org_name}`
               : `${t("common:new")}: ${t("common:organization")}`
           }
           onClose={onClose}
           onConfirm={submitForm}
           isConfirmDisabled={isSubmitting}
         >
          <Form>
            <Grid
              container
              direction="column"
              spacing={3}
              style={{ marginBottom: "12px" }}
            >
              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <FormTextField
                    name={`org_name`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:org_name")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    name={`org_type`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:type")}
                  >
                    {org_type.map((item) => (
                      <MenuItem value={item.org_type} key={item.org_type}>
                        {item.org_type}
                      </MenuItem>
                    ))}
                  </FormSelect>
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.address`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:address")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.addresssupplement1`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:addresssupplement1")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.addresssupplement2`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:addresssupplement2")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.addresssupplement3`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:addresssupplement3")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.street`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:street")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.postalcode`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:postalcode")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.city`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:city")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`meta.country`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:country")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <FormTextField
                    name={`meta.traderidentificationnumber`}
                    variant="outlined"
                    size="lg"
                    fullWidth
                    label={t("common:traderidentificationnumber")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FormDialog>
      )}
    </Formik>
  );
}

export default OrganizationDialog;
