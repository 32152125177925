import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core";
import SchemaForm from "components/SchemaForm";
import {
  ActionBar,
  ActionBarItem,
  Button,
  Title,
  Wrapper,
} from "@periplus/ui-library";
import useStartTrip from "graphql/trips/useStartTrip";
import useInsertTripAction from "graphql/trips/useInsertTripAction";
import useUpdateTripAction from "graphql/trips/useUpdateTripAction";
import { useHistory } from "react-router-dom";
import { useAuth } from "keycloak/context/AuthContext";
import { JSONSchema7 } from "json-schema";
import { UiSchema } from "@rjsf/core";

const useStyles = makeStyles({
  actionBar: {
    marginTop: 24,
  },
  wrapper: {
    backgroundColor: "white",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 16,
    width: "100%",
  },
});

interface TripFormProps {
  trip_id?: any;
  trip_type: string;
  trip_action: string;
  actionData?: any;
  schema: JSONSchema7;
  uiSchema: UiSchema;
}

const TripForm: FC<TripFormProps> = ({
  trip_id,
  trip_type,
  trip_action,
  actionData,
  schema,
  uiSchema,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const schemaFormRef = useRef<any>();

  const [submitting, setSubmitting] = useState(false);

  const { idToken } = useAuth();

  const startTrip = useStartTrip();
  const insertTripAction = useInsertTripAction();
  const updateTripAction = useUpdateTripAction();

  const filesFields = useMemo(() => {
    const schemaProperties = (schema.properties || {}) as any;
    return Object.keys(schemaProperties).filter(
      (key) =>
        schemaProperties[key].type === "string" &&
        schemaProperties[key].format === "data-url"
    );
  }, [schema]);

  const [transformedActionData, setTransformedActionData] = useState();

  useEffect(() => {
    const newTransformedActionData = { ...actionData };
    for (const newFormDataKey in actionData) {
      if (filesFields.includes(newFormDataKey)) {
        newTransformedActionData[
          newFormDataKey
        ] = `data:;name=${newTransformedActionData[newFormDataKey]
          .split("/")
          .pop()};base64,`;
      }
    }
    setTransformedActionData(newTransformedActionData);
  }, [actionData, filesFields]);

  const uploadFile = useCallback(
    ({
      base64File,
      trip_id,
      trip_action,
      trip_action_field,
    }: {
      base64File: string;
      trip_id: number;
      trip_action: string;
      trip_action_field: string;
    }) => {
      const name = base64File.split(";")[1].split("=")[1];
      return new Promise(async (resolve, reject) => {
        try {
          const blobUri = `https://staging.docs.commodity.periplus.ch/${trip_id}/${trip_action}/${trip_action_field}/${name}`;
          const fileUploadFormData = new FormData();
          fileUploadFormData.append(
            "File",
            await (await fetch(base64File)).blob(),
            name
          );
          await fetch(blobUri, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            body: fileUploadFormData,
          });
          resolve({ name, blobUri });
        } catch (error) {
          reject({ name });
        }
      });
    },
    [idToken]
  );

  const handleSubmit = useCallback(
    async ({ formData }) => {
      setSubmitting(true);
      let updatedTripId = trip_id;
      if (!trip_id) {
        const startTripResult = await startTrip({
          tripType: trip_type,
        });
        updatedTripId = startTripResult.data.insert_trip_one.trip_id;
      }
      const transformedFormData = { ...formData };
      for (const formDataKey in transformedFormData) {
        if (filesFields.includes(formDataKey)) {
          if (transformedFormData[formDataKey].split(";").pop() !== "base64,") {
            const data = (await uploadFile({
              base64File: transformedFormData[formDataKey],
              trip_id: updatedTripId,
              trip_action,
              trip_action_field: formDataKey,
            })) as any;
            transformedFormData[formDataKey] = data.blobUri;
          } else {
            transformedFormData[formDataKey] = actionData[formDataKey];
          }
        }
      }
      if (actionData) {
        await updateTripAction({
          trip_id: updatedTripId,
          action_name: trip_action,
          action_params: transformedFormData,
        });
      } else {
        await insertTripAction({
          tripId: updatedTripId,
          action: trip_action,
          params: transformedFormData,
        });
      }
      setSubmitting(false);
      history.push(`/dashboard?trip_type=${trip_type}`);
    },
    [
      trip_id,
      history,
      insertTripAction,
      updateTripAction,
      startTrip,
      uploadFile,
      actionData,
      trip_action,
      trip_type,
      filesFields,
    ]
  );

  return (
    <>
      <Title showDivider>{schema.title}</Title>
      <ActionBar className={classes.actionBar} justify="space-between">
        <ActionBarItem>
          <Button
            variant="outlined"
            color="grey1"
            size="lg"
            onClick={() => history.push(`/dashboard?trip_type=${trip_type}`)}
            style={{
              borderColor: "#c3b8b8",
            }}
          >
            Cancel
          </Button>
        </ActionBarItem>
        <ActionBarItem>
          <Button
            variant="contained"
            color="primary"
            size="lg"
            loading={submitting}
            onClick={() => schemaFormRef.current.submit()}
          >
            Submit
          </Button>
        </ActionBarItem>
      </ActionBar>
      <Wrapper className={classes.wrapper} elevation={2}>
        <SchemaForm
          schema={{ ...schema, title: "" }}
          ref={(form) => (schemaFormRef.current = form)}
          formData={transformedActionData}
          onSubmit={handleSubmit}
          onChange={(e) => setTransformedActionData(e.formData)}
          uiSchema={uiSchema}
        />
      </Wrapper>
    </>
  );
};

export default TripForm;
