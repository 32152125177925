import React from "react";

import { useAuth } from "keycloak/context/AuthContext";

const Login = () => {
  const { loginWithRedirect } = useAuth();

  loginWithRedirect({
    appState: { targetUrl: "/" },
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "centre",
      }}
    >
      Loading...
    </div>
  );
};

export default Login;
