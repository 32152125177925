import React from "react";
import { Paper } from "@material-ui/core";

const SliderPreview = ({ data, index }) => (
  <Paper elevation={6}>
    <img
      style={{ width: "100%" }}
      src={data[index]}
      alt="document page preview"
      data-pp="slide-preview"
    />
  </Paper>
);

export default SliderPreview;
