import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { INSERT_TRIP_ACTION } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";
import { JSONSchema7 } from "json-schema";

const useInsertTripAction = () => {
  const [mutation] = useMutation(INSERT_TRIP_ACTION);
  const withErrorHandling = useErrorHandling();

  const mutationWithErrorHandling = useCallback(
    async (variables: {
      tripId: string;
      action: string;
      params: JSONSchema7;
    }) => {
      return withErrorHandling(mutation, {
        variables,
      });
    },
    [mutation, withErrorHandling]
  );

  return mutationWithErrorHandling;
};

export default useInsertTripAction;
