import React, { FC, useCallback, useMemo } from "react";
import { Formik, Form } from "formik";
import { FormFileField, Button } from "@periplus/ui-library";
import config from "config";
import { SasUpdatePolicyFactory, SasStore } from "utils/blockBlobClient";
import {
  BlockBlobClient,
  AnonymousCredential,
  newPipeline,
} from "@azure/storage-blob";

const Organization: FC = () => {
  const initialValues = useMemo(
    () => ({
      logo: [],
    }),
    []
  );

  const handleSubmit = useCallback(async (values) => {
    const logo = values.logo[0] as File;
    const blobUri = `https://${config.blobStorage.storageAccountName}.blob.core.windows.net/${config.blobStorage.commodity}/orgs_logo/${logo.name}`;
    const sasStore = new SasStore();
    const pipeline = newPipeline(new AnonymousCredential());
    // Inject SAS update policy factory into current pipeline
    pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));
    const blockBlobClient = new BlockBlobClient(
      `${blobUri}?sv=2020-02-10&ss=b&srt=sco&sp=rwdlacx&se=2021-06-07T15:47:44Z&st=2021-04-07T07:47:44Z&spr=https&sig=gYvhd6o%2FVl67SdpxK9Kwo9F85ZJvpJA0ZqWofDistHo%3D`,
      pipeline
    );

    await blockBlobClient.uploadBrowserData(logo, {
      blobHTTPHeaders: {
        blobCacheControl: "max-age=300, must-revalidate",
      },
      maxSingleShotSize: 4 * 1024 * 1024,
    });
    console.log(blobUri);
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <FormFileField name="logo" label="Logo" multiple={false} />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={submitForm}
            size="lg"
            loading={isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Organization;
