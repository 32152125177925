import { BaseRequestPolicy, HttpOperationResponse } from "@azure/storage-blob";
import client from "graphql/client";
import { GET_SAS_TOKEN } from "graphql/sasToken";
import * as Cookies from "js-cookie";

class SasStore {
  // Get a valid SAS for blob
  public getValidSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ) {
    let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;

    if (contentDisposition) {
      key = key + "-" + contentDisposition;
      return window.sessionStorage.getItem(key);
    }

    return Cookies.get(key);
  }

  // Get a new SAS for blob, we assume a SAS starts with a "?"
  public async updateSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ): Promise<string> {
    const { data } = await client.query({
      query: GET_SAS_TOKEN,
      fetchPolicy: "no-cache",
      variables: {
        container,
        contentType,
        contentDisposition,
      },
    });

    if (data) {
      let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;
      const value = data.getSASToken.sasToken;

      if (contentDisposition) {
        key = key + "-" + contentDisposition;
        window.sessionStorage.setItem(key, value);
      } else {
        Cookies.set(key, value, {
          expires: new Date(data.getSASToken.expiryDate),
        });
      }
      return await data.getSASToken.sasToken;
    }

    return await data;
  }
}

class SasUpdatePolicyFactory {
  private readonly sasStore: any;

  constructor(sasStore: any) {
    this.sasStore = sasStore;
  }

  create(nextPolicy: any, options: any) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}

class SasUpdatePolicy extends BaseRequestPolicy {
  private sasStore: any;

  constructor(nextPolicy: any, options: any, sasStore: any) {
    super(nextPolicy, options);
    this.sasStore = sasStore;
  }

  async sendRequest(request: any): Promise<HttpOperationResponse> {
    const urlObj = new URL(request.url);
    const sas = await this.sasStore.getValidSASForContainer(
      `${urlObj.origin}${urlObj.pathname}`
    );
    new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
    });

    // Update request URL with latest SAS
    request.url = urlObj.toString();

    return this._nextPolicy.sendRequest(request);
  }
}

export { SasUpdatePolicy, SasUpdatePolicyFactory, SasStore };
