import gql from "graphql-tag";

export const GET_TRIP_TYPE_CONFIGURATIONS = gql`
  query GET_TRIP_TYPE_CONFIGURATIONS($trip_type: String) {
    trip_types(where: { trip_type: { _eq: $trip_type } }) {
      trip_type
      description
      display_name
      trip_schema
      start_state
      state_diagram
    }
    trip_type_states(
      where: { trip_type: { _eq: $trip_type } }
      order_by: { display_order: asc }
    ) {
      display_name
      trip_state
      trip_type
      description
    }
    trip_type_actions(
      where: { trip_type: { _eq: $trip_type } }
      order_by: { action_order: asc }
    ) {
      action_name
      action_order
      action_roles
      display_name
      state_post
      state_pre
      trip_type
    }
  }
`;
