import React, { FC, useState, useCallback, useMemo } from "react";
import {
  makeStyles,
  Theme,
  Box,
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  Drawer,
  Tooltip,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import { useTranslation } from "react-i18next";
import theme from "styles";
import { useLocation, useHistory } from "react-router-dom";
import { SimpleMenu } from "components/Menus";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import { displayValidUsername } from "utils/userNameHelper";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import { useAuth, Role } from "keycloak";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import getDownloadUrl from "utils/azureBlobStorageUtils";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import LibraryBooks from "@material-ui/icons/LibraryBooksOutlined";

interface NavigationProps {
  notifications?: any[];
}

const drawerWidth = 280;
const section = {
  display: "flex",
  alignItems: "center",
  height: "72px",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
};

const useStyles = makeStyles((theme: Theme) => {
  const closedDrawerWidth = theme.spacing(7) + 1;
  const smClosedDrawerWidth = theme.spacing(9) + 1;

  return {
    appBar: {
      backgroundColor: "white",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1);",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      width: `calc(100% - ${closedDrawerWidth}px)`,
      marginLeft: closedDrawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${smClosedDrawerWidth}px)`,
        marginLeft: smClosedDrawerWidth,
      },
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxSizing: "border-box",
    },
    appBarShift: {
      width: `calc(100vw - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarSectionFirst: {
      color: theme.palette.grey[600],
      paddingRight: theme.spacing(2),
      ...section,
    },
    appBarSection: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      color: theme.palette.grey[600],
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      ...section,
    },
    appBarSectionLast: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      color: theme.palette.grey[600],
      paddingLeft: theme.spacing(2),
      ...section,
    },
    sideBarToggleButtonIcon: {
      color: theme.palette.grey[600],
    },
    title: {
      textTransform: "capitalize",
      color: theme.palette.grey[600],
    },
    notificationsIconButton: {
      width: "48px",
      height: "48px",
      display: "flex",
      color: "#00527E",
      position: "relative",

      "& *:hover": {
        textDecoration: "none",
      },
    },
    newNotifications: {
      width: 10,
      height: 10,
      position: "absolute",
      right: 13,
      top: 13,
      backgroundColor: "#EC008C",
      borderRadius: "100%",
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    paper: {
      padding: `0 ${theme.spacing(3)}px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "hidden",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.14)",
      boxSizing: "border-box",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    logoContainer: {
      height: 72,
      "& :first-child": {
        cursor: "pointer",
        marginTop: 16,
      },
    },
    content: {
      padding: theme.spacing(2.5),
      overflow: "hidden",
      overflowY: "auto",
      height: "calc(100vh - 72px)",
      backgroundColor: "#f3f4fb",
      boxSizing: "border-box",
    },
    buttonLink: {
      color: theme.palette.primary.main,
    },
    label: {
      textTransform: "capitalize",
    },
    navigationItem: {
      padding: `${theme.spacing(0.5)}px 0`,
      color: theme.palette.grey[600],
      "&:hover *": {
        color: theme.palette.primary.main,
      },
    },
    navigationItemSelected: {
      "&.Mui-selected": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.common.white,
        },
        "& .MuiListItemIcon-root": {
          color: "inherit",
        },
      },
    },
  };
});

const Navigation: FC<NavigationProps> = ({ children, notifications = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { user, logout } = useAuth();
  const history = useHistory();

  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [personalMenuOpen, setPersonalMenuOpen] = useState(false);

  const togglePersonalMenuOpen = () => {
    setPersonalMenuOpen((prev) => !prev);
  };

  const title = useMemo(() => {
    const pathname = location.pathname.split("/");
    const lastItem = pathname[pathname.length - 1];
    return t(`navigation:${lastItem}`);
  }, [location, t]);

  const personalMenuUserName = useMemo(
    () => displayValidUsername((user && user.display_name) || ""),
    [user]
  );

  const menuItems = useMemo(
    () => [
      {
        id: 1,
        Text: t("common:logout"),
        action: () => logout(),
      },
    ],
    [logout, t]
  );

  const navigation: any = useMemo(() => {
    const getGroupTitle = (full, short) =>
      t(`navigation:${sideBarOpen ? full : short}`);

    const operativeGroup = {
      groupTitle: getGroupTitle("operative", "ops"),
      items: [
        {
          Icon: DashboardOutlinedIcon,
          text: t("navigation:dashboard"),
          redirecting: "/dashboard",
        },
        {
          Icon: LibraryBooks,
          text: t("navigation:organization"),
          redirecting: "/organization-management",
        },
      ],
    };

    if (user?.roles?.includes(Role.ADMIN)) {
      operativeGroup.items.push({
        Icon: AssignmentOutlinedIcon,
        text: t("common:settings"),
        redirecting: "/settings/tripType/edit",
      });
    }

    return [operativeGroup];
  }, [sideBarOpen, t, user]);

  const handleSideBarToggle = useCallback(
    () => setSideBarOpen((prev) => !prev),
    []
  );

  return (
    <Box display="flex" flexDirection="column">
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sideBarOpen,
        })}
        elevation={0}
        color="default"
      >
        <Toolbar disableGutters>
          <Grid
            container
            justify="space-between"
            wrap="nowrap"
            style={{ alignSelf: "stretch" }}
          >
            <Grid
              className={classes.appBarSectionFirst}
              item
              container
              alignItems="center"
            >
              <IconButton onClick={handleSideBarToggle} edge="start">
                <MenuIcon className={classes.sideBarToggleButtonIcon} />
              </IconButton>
              <Typography className={classes.title} variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item container justify="flex-end">
              <Grid item className={classes.appBarSection}>
                <Tooltip title={t("tooltips:checkNotifications") || ""}>
                  <IconButton className={classes.notificationsIconButton}>
                    {!!notifications.length && (
                      <span className={classes.newNotifications} />
                    )}
                    <NotificationsIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item className={classes.appBarSection}>
                <SimpleMenu
                  toggleMenu={togglePersonalMenuOpen}
                  elements={menuItems}
                  renderButton={({ onClick }) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <PersonOutlineRoundedIcon
                        style={{ marginRight: theme.spacing(0.5) }}
                      />
                      <Button
                        classes={{
                          root: classes.buttonLink,
                          label: classes.label,
                        }}
                        onClick={onClick}
                      >
                        <Typography style={{ marginTop: 2 }} variant="body1">
                          {personalMenuUserName.map((identity: string) => (
                            <span
                              key={identity}
                              style={{ textTransform: "capitalize" }}
                            >
                              {identity}{" "}
                            </span>
                          ))}
                        </Typography>
                        {personalMenuOpen ? (
                          <ArrowDropUpRoundedIcon />
                        ) : (
                          <ArrowDropDownRoundedIcon />
                        )}
                      </Button>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: sideBarOpen,
            [classes.drawerClose]: !sideBarOpen,
          }),
        }}
      >
        <Grid container direction="column" justify="space-between">
          <Box
            display="flex"
            alignItems="center"
            style={{ minHeight: 72 }}
            onClick={() => history.push("/dashboard")}
          >
            {user?.org_logo && (
              <img src={getDownloadUrl(user?.org_logo)} alt="Logo" />
            )}
          </Box>
          <Grid item container direction="column">
            {navigation.map(({ groupTitle, items }, index) => (
              <Box key={index}>
                <Typography
                  variant="overline"
                  style={{ color: theme.palette.grey[600] }}
                >
                  {groupTitle}
                </Typography>
                <List>
                  {items.map(({ Icon, text, redirecting }, index) => (
                    <ListItem
                      className={classes.navigationItem}
                      button
                      key={index}
                      onClick={() => history.push(redirecting)}
                      selected={location.pathname === redirecting}
                      classes={{
                        selected: classes.navigationItemSelected,
                      }}
                    >
                      {Icon && (
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Drawer>
      <Box
        className={clsx(classes.content, classes.appBar, {
          [classes.appBarShift]: sideBarOpen,
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Navigation;
