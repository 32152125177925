import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { DELETE_TRIP_ACTION } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useDeleteTripAction = () => {
  const [update] = useMutation(DELETE_TRIP_ACTION);
  const withErrorHandling = useErrorHandling();

  const updateTripTypeAction = useCallback(
    async (variables: { trip_id: any; action_name: string }) => {
      withErrorHandling(update, {
        variables,
      });
    },
    [update, withErrorHandling]
  );

  return updateTripTypeAction;
};

export default useDeleteTripAction;
