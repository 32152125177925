import { useMemo } from "react";
import { useQuery } from "react-apollo";
import { GET_REFS } from "./queries";

const useGetRefs = () => {
  const result = useQuery(GET_REFS);

  return useMemo(
    () => ({
      ...result,
      data: { pp_refs_js: result.data?.pp_refs_js || [] },
    }),
    [result]
  );
};

export default useGetRefs;
