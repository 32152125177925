import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { ArrayFieldTemplateProps } from "@rjsf/core";
import { Title } from "@periplus/ui-library";

import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles({
  arrayWrap: {
    marginBottom: 8,
    padding: 4,
    borderRadius: "4px",
  },
  title: {
    marginBottom: 0,
  },
});

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const classes = useStyles();

  const isString =
    //@ts-ignore
    props.schema.items.type === "string";

  const arrayCard = isString
    ? {
        paddingTop: "10px",
        padding: 0,
      }
    : {
        backgroundColor: "white",
        paddingRight: "16px",
        paddingLeft: "16px",
        paddingTop: "10px",
        marginTop: "12px",
        marginBottom: "12px",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.14)",
      };

  const ButtonType = ({ action, icon, ...rest }) => (
    <Button
      variant="outlined"
      disableElevation
      onClick={action}
      style={{ marginLeft: 8, marginBottom: 8, minHeight: "56px" }}
      {...rest}
    >
      {icon}
    </Button>
  );

  const { required, title, items, canAdd, disabled, onAddClick } = props;

  return (
    <Grid
      xs={12}
      item
      container
      direction="column"
      spacing={1}
      className={classes.arrayWrap}
    >
      <Grid
        item
        container
        direction="row"
        style={{ marginBottom: 16, padding: 0 }}
      >
        <Title showDivider>{title}</Title>
        {required && <Title> *</Title>}
      </Grid>
      {items.map((element, i) => (
        <Grid
          item
          container
          direction="row"
          xs={12}
          style={{ ...arrayCard }}
          key={element.key}
        >
          <Grid item style={{ flex: 1, padding: 0 }}>
            {element.children}
          </Grid>
          <Grid
            item
            style={{
              alignSelf: "center",
              display: "flex",
              flexDirection: isString ? "row" : "column",
              marginLeft: isString ? "none" : 8,
              padding: 0,
            }}
          >
            {element.hasRemove && (
              <ButtonType
                disabled={element.disabled}
                action={element.onDropIndexClick(i)}
                color="secondary"
                icon={<DeleteIcon />}
              />
            )}
            {element.hasMoveUp && (
              <ButtonType
                disabled={element.disabled}
                color="primary"
                action={element.onReorderClick(i, i - 1)}
                icon={<ArrowUpwardIcon />}
              />
            )}
            {element.hasMoveDown && (
              <ButtonType
                disabled={element.disabled}
                color="primary"
                action={element.onReorderClick(i, i + 1)}
                icon={<ArrowDownwardIcon />}
              />
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} style={{ padding: 0 }}>
        {canAdd && (
          <Button
            disabled={disabled}
            variant="outlined"
            color="primary"
            disableElevation
            onClick={onAddClick}
          >
            Add Item
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ArrayFieldTemplate;
