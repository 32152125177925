import gql from "graphql-tag";

export const GET_SAS_TOKEN = gql`
  query getSASToken(
    $container: String!
    $contentType: String
    $contentDisposition: String
  ) {
    getSASToken(
      container: $container
      contentType: $contentType
      contentDisposition: $contentDisposition
    ) {
      container
      sasToken
      expiryDate
    }
  }
`;
