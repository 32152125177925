import gql from "graphql-tag";

export const ADD_TRIP_TYPE_ACTION_PERMISSIONS = gql`
  mutation ADD_TRIP_TYPE_ACTION_PERMISSIONS(
    $tripActionPermissions: [trip_action_perm_insert_input!]!
  ) {
    insert_trip_action_perm(
      objects: $tripActionPermissions
      on_conflict: {
        constraint: pk_trip_action_perm
        update_columns: action_name
      }
    ) {
      affected_rows
    }
  }
`;
