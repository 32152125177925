import React, { useMemo } from "react";
import { RouteProps } from "react-router";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Text from "components/Text/Text";
import { Button, Dialog } from "@periplus/ui-library";
import { DialogVariant } from "@periplus/ui-library/dist/components/Dialog/Dialog";

interface IDialogProps extends RouteProps {
  onClose(): void;
  onConfirm(): void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  message: string;
  variant?: DialogVariant;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginButton: {
      margin: theme.spacing(1)
    },
  })
);

const ConfirmationDialog: React.FC<IDialogProps> = ({
  onClose,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  message,
  variant = "warning",
}) => {
  const classes = useStyles();

  const actions = useMemo(() => {
    return (
      <>
        <Button onClick={onClose} classes={{ root: classes.marginButton }}>
          <Text variant="subtitle2">{cancelButtonText || "common:cancel"}</Text>
        </Button>
        <Button onClick={onConfirm} classes={{ root: classes.marginButton }}>
          <Text variant="subtitle2">
            {confirmButtonText || "common:confirm"}
          </Text>
        </Button>
      </>
    );
  }, [cancelButtonText, confirmButtonText, onClose, onConfirm, classes.marginButton]);

  return (
    <Dialog
      open
      variant={variant}
      message={message}
      onClose={onClose}
      actions={actions}
    />
  );
};

export default ConfirmationDialog;
