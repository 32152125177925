import React, { FC, useCallback, useMemo, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Loader } from "@periplus/ui-library";
import { useGetTripTypes } from "graphql/tripType";
import { TripsPanel, DashboardMap } from "./components";
import { ITrip } from "graphql/trips";

const useStyles = makeStyles({
  tripsPanelContainer: {
    marginTop: 16,
    flexGrow: 1,
  },
});

const Dashboard: FC = () => {
  const classes = useStyles();

  const [selectedTrip, setSelectedTrip] = useState<ITrip>();

  const handleChangeSelectedTrip = useCallback((trip) => {
    setSelectedTrip(trip);
  }, []);

  const {
    data: { trip_types },
    loading: tripTypesLoading,
  } = useGetTripTypes();

  const filteredTripTypes = useMemo(
    () =>
      trip_types
        .filter(
          (el) =>
            el.trip_type === "touton_coffee" || el.trip_type === "minerals"
        )
        .reverse(),
    [trip_types]
  ); //TODO Remove after DEMO

  return (
    <Box>
      <Box style={{ height: "30vh" }}>
        <DashboardMap trip={selectedTrip} />
      </Box>
      <Box className={classes.tripsPanelContainer}>
        {tripTypesLoading ? (
          <Loader />
        ) : (
          <TripsPanel
            trip_types={filteredTripTypes}
            selectedTrip={selectedTrip}
            onChangeSelectedTrip={handleChangeSelectedTrip}
          />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
