import React, { FC, useCallback, useMemo } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { JSONSchema7 } from "json-schema";
import { UiSchema } from "@rjsf/core";
import { getDefaultFormState } from "@rjsf/core/lib/utils";
import { Skeleton } from "@material-ui/lab";

import SchemaForm from "components/SchemaForm";
import Editor from "./Editor";
import { toJson } from "utils/json";
import { useGetRefs } from "graphql/tripTypeAction";

const useStyles = makeStyles((theme: Theme) => ({
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    "& pre": {
      display: "block",
      padding: theme.spacing(1),
      margin: "0 0 10px",
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      color: "#333",
      wordBreak: "break-all",
      wordWrap: "break-word",
      backgroundColor: "#f5f5f5",
      border: "1px solid #ccc",
      borderRadius: 5,
    },
  },
}));

interface SchemaFormPlaygroundProps {
  values: {
    params_schema: JSONSchema7;
    ui_schema?: UiSchema;
    form_data?: any;
    refs?: any;
  };
  onChange: (type: string, value: JSONSchema7 | UiSchema | any) => void;
  loading?: boolean;
}

const SchemaFormPlayground: FC<SchemaFormPlaygroundProps> = ({
  values,
  onChange,
  loading = false,
}) => {
  const classes = useStyles();

  const {
    data: {
      pp_refs_js: [refs],
    },
  } = useGetRefs();

  const schemaJson = toJson(values.params_schema);

  const handleChange = useCallback(
    (type: string) => (schema: JSONSchema7 | UiSchema | any) => {
      onChange(type, schema);
    },
    [onChange]
  );

  const schema = useMemo(
    () => ({
      ...values.params_schema,
      refs: refs?.refs,
    }),
    [refs, values.params_schema]
  );

  let Form: any = null;
  try {
    if (getDefaultFormState(schema, {}, schema)) {
      Form = (
        <SchemaForm
          showSubmit={false}
          schema={schema.title ? schema : { title: "", type: "string" }}
          uiSchema={values.ui_schema}
        />
      );
    }
  } catch (e) {}

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.topSpacing}
      >
        <Grid item md={7}>
          <Grid container direction="column">
            <Skeleton variant="rect" height={350} width="100%" />

            <Grid
              container
              direction="row"
              spacing={2}
              className={classes.topSpacing}
            >
              <Grid item md={6}>
                <Skeleton variant="rect" height={350} width="100%" />
              </Grid>

              <Grid item md={6}>
                <Skeleton variant="rect" height={350} width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={5} className={classes.formContainer}>
          <Skeleton variant="rect" height="100%" width="100%" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" spacing={2} className={classes.topSpacing}>
      <Grid item md={7}>
        <Grid container direction="column">
          <Editor
            height={350}
            title="JSON Schema"
            code={schemaJson}
            onChange={handleChange("params_schema")}
          />

          <Grid
            container
            direction="row"
            spacing={2}
            className={classes.topSpacing}
          >
            <Grid item md={6}>
              <Editor
                height={350}
                title="UI Schema"
                code={toJson(values.ui_schema)}
                onChange={handleChange("ui_schema")}
              />
            </Grid>

            <Grid item md={6}>
              <Editor
                height={350}
                title="Refs"
                code={toJson(refs?.refs)}
                readonly
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={5} className={classes.formContainer}>
        {schemaJson !== "{}" && Form}
      </Grid>
    </Grid>
  );
};

export default SchemaFormPlayground;
