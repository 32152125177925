import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { DELETE_TRIP_STATE_COORDS } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useDeleteTripStateCoords = () => {
  const [deleteTripCoords] = useMutation(DELETE_TRIP_STATE_COORDS);
  const withErrorHandling = useErrorHandling();

  const deleteTripTypeCoords = useCallback(
    async (variables: { trip_type: string; trip_state: string }) => {
      withErrorHandling(deleteTripCoords, {
        variables,
      });
    },
    [deleteTripCoords, withErrorHandling]
  );

  return deleteTripTypeCoords;
};

export default useDeleteTripStateCoords;
