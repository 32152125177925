import React, { FC } from "react";
import Navigation from "components/Navigation";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFoundPage";
import Dashboard from "pages/Dashboard";
import {
  TripInsertAction,
  TripEditAction,
  TripStartAction,
} from "pages/TripActions";
import Organization from "pages/Organization";

import Trip from "pages/Trip";
import TripConfig from "pages/Settings/TripConfig";
import EditActionSchema from "pages/Settings/EditActionSchema";
import EditTripTypeSchema from "pages/Settings/EditTripTypeSchema";
import OrganizationManagement from "pages/OrganizationManagement";
import { Role, useAuth } from "keycloak";

const SettingsRoute: FC = () => {
  const { user } = useAuth();
  const { path } = useRouteMatch();

  if (!user?.roles?.includes(Role.ADMIN)) {
    return <Redirect to="/404" />;
  }

  return (
    <Switch>
      <Route path={`${path}/tripType/create`} exact>
        <TripConfig create />
      </Route>
      <Route path={`${path}/tripType/edit/:tripTypeName?`} exact>
        <TripConfig />
      </Route>
      <Route path={`${path}/tripType/edit/:tripTypeName/schema`} exact>
        <EditTripTypeSchema />
      </Route>
      <Route path={`${path}/action/:action_name/schema`} exact>
        <EditActionSchema />
      </Route>
    </Switch>
  );
};

const AuthRoutes: FC = () => {
  return (
    <Navigation>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/login" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route path="/trips/create" exact>
          <TripStartAction />
        </Route>
        <Route path="/trips/:trip_id/edit" exact>
          <TripEditAction />
        </Route>
        <Route path="/trips/:trip_id/new-action" exact>
          <TripInsertAction />
        </Route>
        <Route path="/trips/:trip_id" exact>
          <Trip />
        </Route>
        <Route path="/organisation" exact>
          <Organization />
        </Route>
        <Route path="/settings">
          <SettingsRoute />
        </Route>
        <Route path="/organization-management" exact component={OrganizationManagement} />
        <Route path="*" exact>
          <NotFoundPage />
        </Route>
      </Switch>
    </Navigation>
  );
};

export default AuthRoutes;
