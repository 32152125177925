import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  makeStyles,
  Theme,
  Grid,
  Button as MuiButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@periplus/ui-library";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import get from "lodash/get";
import { useSnackbar } from "notistack";

import {
  ITripState,
  useCreateTripState,
  useGetTripStateCoords,
  useSaveTripStateCoords,
  useDeleteTripStateCoords,
} from "graphql/tripState";

import Field from "components/Field";
import ExpandablePanel from "components/ExpandablePanel";
import SquareButton from "components/SquareButton";
import { toJson } from "utils/json";

import MapDialog from "./MapDialog";

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  inlineButton: {
    alignSelf: "baseline",
    padding: "8px 10px",
  },
  buttonPlacement: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  mapSelected: {
    color: "green",
  },
}));

interface TripTypeProps {
  onSubmit?: () => void;
  trip_states: ITripState[];
}

const arrayToKeyValueObject = (array, key, value) => {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item[key]]: Array.isArray(value)
        ? value.reduce((acc, v) => {
            const { name, key } =
              typeof v === "object" ? v : { name: v, key: v };
            return { ...acc, [name]: get(item, key) };
          }, {})
        : value,
    }),
    {}
  );
};

const TripStatePage: FC<TripTypeProps> = ({ onSubmit, trip_states }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();
  const defaultStateObject = {
    trip_state: "",
    display_name: "",
    description: "",
    trip_type: tripTypeName || "",
    meta: {},
  };

  const deleteTripStateCoords = useDeleteTripStateCoords();
  const saveTripStateCoords = useSaveTripStateCoords();
  const createTripState = useCreateTripState();
  const {
    data: { trip_state_coords },
    refetch,
  } = useGetTripStateCoords({ trip_type: tripTypeName });

  const [selectedState, setSelectedState] = useState("");
  const [expanded, setExpanded] = useState(!!tripTypeName);
  const [tripStateErrors, setTripStateErrors] = useState<
    { [key: string]: string }[]
  >([]);
  const [tripState, setTripState] = useState<ITripState[]>([]);

  const selectedCoordinates = useMemo(
    () =>
      arrayToKeyValueObject(trip_state_coords, "trip_state", [
        {
          name: "coords",
          key: "coords.coordinates",
        },
        "notes",
      ]),
    [trip_state_coords]
  );

  useEffect(() => {
    const initialServerTripState =
      trip_states.length === 0 ? [defaultStateObject] : [...trip_states];
    setTripState(initialServerTripState);
    setTripStateErrors([]);
    setExpanded(true);
    // eslint-disable-next-line
  }, [trip_states]);

  useEffect(() => {
    if (!tripTypeName) {
      setTripState([]);
      setExpanded(false);
    }
  }, [setTripState, tripTypeName]);

  const validate = useCallback(
    (name, value, index) => {
      setTripStateErrors((errors) => {
        if (name === "description") {
          return errors;
        }

        const foundValue = tripState.find(
          (tState) => tState[name].toLowerCase() === value.toLowerCase()
        );

        if (!!foundValue) {
          errors[index] = { ...errors[index], [name]: value };
          return [...errors];
        }

        if (!value || errors[index]?.[name]) {
          delete errors[index]?.[name];
          return errors.filter((error) => toJson(error) !== "{}");
        }

        return errors;
      });
    },
    [tripState]
  );

  const handleChange = useCallback(
    (index: number) => (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      const { name, value } = e.target;

      validate(name, value, index);

      setTripState((prevTripState) => {
        prevTripState[index] = {
          ...prevTripState[index],
          [name]: value,
        };
        return [...prevTripState];
      });
    },
    [validate]
  );

  const handleAddNewLine = () =>
    setTripState((tState) => tState.concat(defaultStateObject));

  const handleRemoveLine = (index: number) => () => {
    setTripStateErrors((errors) => {
      return errors.filter((_, i) => i !== index);
    });

    setTripState((tState) => {
      return tState.filter((_, i) => i !== index);
    });
  };

  const handleSave = () => {
    createTripState(tripState).then(() => {
      onSubmit?.();
    });
  };

  const handleSetCoordinates = (coordinates, userNotes) => {
    const onSuccess = () => {
      setTimeout(() => {
        enqueueSnackbar("Location successfully saved", {
          variant: "success",
        });
        refetch();
      }, 500);
    };

    if (coordinates.length === 0) {
      deleteTripStateCoords({
        trip_type: tripTypeName,
        trip_state: selectedState,
      }).then(onSuccess);
      return;
    }

    saveTripStateCoords({
      trip_type: tripTypeName,
      trip_state: selectedState,
      coords: `{ 'type': 'Point', 'coordinates': [${coordinates}] }`,
      notes: userNotes,
    }).then(onSuccess);
  };

  return (
    <>
      <ExpandablePanel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        disabled={!tripTypeName}
        title="Trip States"
        subTitle="Configuration of states for trip type"
        actions={
          <Button
            className={classes.button}
            size="md"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              toJson(tripState) === toJson(trip_states) ||
              tripState.some(
                (tState) =>
                  !tState.trip_state?.trim() || !tState.display_name?.trim()
              ) ||
              tripStateErrors.filter(Boolean).length > 0
            }
          >
            Save
          </Button>
        }
      >
        <Grid container direction="column">
          {tripState.map((tState, index) => (
            <React.Fragment key={index}>
              <Grid container direction="row" wrap="nowrap">
                <Field
                  classes={{
                    root: classes.inputContainer,
                  }}
                  required
                  error={!!tripStateErrors[index]?.trip_state}
                  label="Name"
                  name="trip_state"
                  value={tState.trip_state}
                  onChange={handleChange(index)}
                  disabled={index < trip_states.length}
                  helperText={
                    !!tripStateErrors[index]?.trip_state
                      ? `Name: "${tripStateErrors[index]?.trip_state}" already exist`
                      : undefined
                  }
                />

                <Field
                  classes={{
                    root: classes.inputContainer,
                  }}
                  required
                  error={!!tripStateErrors[index]?.display_name}
                  label="Display Name"
                  name="display_name"
                  value={tState.display_name}
                  onChange={handleChange(index)}
                  helperText={
                    !!tripStateErrors[index]?.display_name
                      ? `Display Name: "${tripStateErrors[index]?.display_name}" already exist`
                      : undefined
                  }
                />

                <Field
                  classes={{
                    root: classes.inputContainer,
                  }}
                  error={!!tripStateErrors[index]?.description}
                  label="Description"
                  name="description"
                  value={tState.description}
                  onChange={handleChange(index)}
                  helperText={
                    !!tripStateErrors[index]?.description
                      ? `Display Name: "${tripStateErrors[index]?.description}" already exist`
                      : undefined
                  }
                />

                {index < trip_states.length && (
                  <div>
                    <SquareButton
                      variant="outlined"
                      color="primary"
                      className={clsx(classes.buttonPlacement, {
                        [classes.mapSelected]: !!selectedCoordinates[
                          tState.trip_state
                        ]?.coords,
                      })}
                      onClick={() => setSelectedState(tState.trip_state)}
                      title="Add map pointer"
                    >
                      <AddLocationIcon />
                    </SquareButton>
                  </div>
                )}

                {index >= trip_states.length && tripState.length !== 1 && (
                  <div>
                    <SquareButton
                      color="secondary"
                      onClick={handleRemoveLine(index)}
                      variant="contained"
                      size="small"
                      className={classes.buttonPlacement}
                    >
                      <DeleteIcon />
                    </SquareButton>
                  </div>
                )}
              </Grid>
              {index + 1 === tripState.length && (
                <MuiButton
                  color="primary"
                  onClick={handleAddNewLine}
                  variant="contained"
                  size="small"
                  className={clsx(
                    classes.inlineButton,
                    classes.buttonPlacement
                  )}
                >
                  <AddIcon />
                  Add line
                </MuiButton>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </ExpandablePanel>
      <MapDialog
        open={!!selectedState}
        onClose={() => setSelectedState("")}
        coordinates={selectedCoordinates[selectedState]?.coords}
        notes={selectedCoordinates[selectedState]?.notes}
        title={selectedState}
        onSave={handleSetCoordinates}
      />
    </>
  );
};

export default TripStatePage;
