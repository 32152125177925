import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { START_TRIP } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useStartTrip = () => {
  const [mutation] = useMutation(START_TRIP);
  const withErrorHandling = useErrorHandling<{
    data: { insert_trip_one: { trip_id: string } };
  }>();

  const mutationWithErrorHandling = useCallback(
    async (variables: { tripType: string }) => {
      return withErrorHandling(mutation, {
        variables,
      });
    },
    [mutation, withErrorHandling]
  );

  return mutationWithErrorHandling;
};

export default useStartTrip;
