import React from "react";

const SliderThumbnail = ({ data, index, style }) => {
  const item = data[index];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid #C8C8D4`,
        borderRadius: 4,
        overflow: "hidden",
        ...style,
        width: 126,
        height: 161,
      }}
      data-pp="slide"
    >
      <img
        style={{
          width: "100%",
          height: "100%",
        }}
        src={item}
        alt="document page preview"
        key={item}
      />
    </div>
  );
};
export default SliderThumbnail;
