import React, { FC, useEffect, useState } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { IconButton, Tooltip } from "@material-ui/core";
import { downloadBlob } from "utils/azureBlobStorageUtils";
import { useAuth } from "keycloak/context/AuthContext";

interface DownloadButtonProps {
  url: string;
  className: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({ url, className }) => {
  const { idToken } = useAuth();
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    downloadBlob(url, idToken).then((result) => {
      setFileUrl(result);
    });
  }, [url, idToken]);

  return (
    <a href={fileUrl} download={url.split("/").pop()}>
      <Tooltip title="Download">
        <IconButton size="small">
          <GetAppOutlinedIcon className={className} />
        </IconButton>
      </Tooltip>
    </a>
  );
};

export default DownloadButton;
