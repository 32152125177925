import { useMemo } from "react";
import { OperationVariables, useQuery } from "react-apollo";
import { GET_TRIP_TYPE_CONFIGURATIONS } from "./queries";
import { ITripTypeConfig } from "graphql/tripType";
import { ITripState } from "graphql/tripState";
import { ITripTypeAction } from "graphql/tripTypeAction";
import { fromJson, toJson } from "utils/json";

interface ITripTypeConfiguration {
  trip_types: ITripTypeConfig[];
  trip_type_states: ITripState[];
  trip_type_actions: ITripTypeAction[];
}

const sortTripActions = (
  tripTypeStartAction?: string,
  tripActions: ITripTypeAction[] = []
) => {
  const order = [tripTypeStartAction];

  const ordered = tripActions?.reduce((acc, tripAction) => {
    return {
      ...acc,
      [tripAction.state_pre]: tripAction.state_post,
    };
  }, {});

  let prevState = tripTypeStartAction;
  for (let i = 0; i < tripActions.length - 1; i++) {
    if (!prevState) {
      return;
    }
    const newState = ordered[prevState];
    order.push(newState);
    prevState = newState;
  }

  const tripActionsCopy = [...tripActions];
  tripActionsCopy.sort((a, b) => {
    const firstIndex = order.indexOf(a.state_pre);
    const secondIndex = order.indexOf(b.state_pre);

    const first = firstIndex === -1 ? Number.MAX_SAFE_INTEGER : firstIndex;
    const second = secondIndex === -1 ? Number.MAX_SAFE_INTEGER : secondIndex;
    const result = first - second;

    return result;
  });

  return tripActionsCopy;
};

const useGetTripTypeConfigurations = (
  variables: { trip_type?: string } = {},
  options: OperationVariables = {}
) => {
  const defaultState = {
    trip_types: [],
    trip_type_states: [],
    trip_type_actions: [],
  };

  const result = useQuery<ITripTypeConfiguration>(
    GET_TRIP_TYPE_CONFIGURATIONS,
    {
      variables,
      ...options,
    }
  );

  return useMemo(
    () => {
      const omitTypename = (key, value) =>
        key === "__typename" ? undefined : value;
      const formattedData = result.data
        ? fromJson(toJson(result.data), omitTypename)
        : result.data;
      const sortedData = sortTripActions(
        formattedData?.trip_types[0].start_state,
        formattedData?.trip_type_actions
      );
      return {
        ...result,
        data: Object.assign(defaultState, formattedData, {
          trip_type_actions: sortedData,
        }),
      };
    },
    // eslint-disable-next-line
    [result]
  );
};

export default useGetTripTypeConfigurations;
