import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { CREATE_TRIP_TYPE_ACTION } from "./queries";
import {
  ADD_TRIP_TYPE_ACTION_PERMISSIONS,
  ITripTypeActionPerm,
} from "graphql/tripTypeActionPerm";
import { ITripTypeAction } from "./types";
import useErrorHandling from "hooks/useErrorHandling";

const useCreateTripTypeAction = () => {
  const [createAction] = useMutation(CREATE_TRIP_TYPE_ACTION);
  const [addPermissions] = useMutation(ADD_TRIP_TYPE_ACTION_PERMISSIONS);

  const withErrorHandling = useErrorHandling();

  const createTripTypeAction = useCallback(
    async (variables: ITripTypeAction[]) => {
      const [actionsData, actionsPermissionsData] = variables.reduce<
        [ITripTypeAction[], ITripTypeActionPerm[]]
      >(
        (
          acc,
          { action_name, tripTypeActionPerms, state_post, state_pre, trip_type }
        ) => {
          acc[0].push({
            action_name,
            state_post,
            state_pre,
            trip_type,
          });

          (tripTypeActionPerms || []).forEach((aRole) => {
            acc[1].push({
              action_name,
              action_role: aRole.action_role,
            });
          });

          return acc;
        },
        [[], []]
      );

      withErrorHandling(createAction, {
        variables: {
          tripType: actionsData,
        },
      }).then(() =>
        withErrorHandling(addPermissions, {
          variables: {
            tripActionPermissions: actionsPermissionsData,
          },
        })
      );
    },
    [addPermissions, createAction, withErrorHandling]
  );

  return createTripTypeAction;
};

export default useCreateTripTypeAction;
