import React from "react";
import { FileField } from "@periplus/ui-library";

const fileToDataUrl = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const readerResult = reader.result as string;
      return resolve(
        `${readerResult.substring(0, readerResult.indexOf(";") + 1)}name=${
          file.name
        };${readerResult.substring(readerResult.indexOf(";") + 1)}`
      );
    };
    reader.onerror = (error) => reject(error);
  });
};

const dataUrlToFile = (dataUrl: string) => {
  const fileNameSectionStartIndex = dataUrl.indexOf(";") + 1;
  const fileNameSectionEndIndex = dataUrl.indexOf(
    ";",
    dataUrl.indexOf(";") + 1
  );
  const fileName = dataUrl
    .substring(fileNameSectionStartIndex, fileNameSectionEndIndex)
    .split("=")[1];
  const standartedDataUrl = `${dataUrl.substring(
    0,
    fileNameSectionStartIndex
  )}${dataUrl.substring(fileNameSectionEndIndex)}`;

  const fileType = standartedDataUrl.substring(
    standartedDataUrl.indexOf(":") + 1,
    standartedDataUrl.indexOf(";")
  );
  const arr = standartedDataUrl.split(",");
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: fileType });
};

const FileWidget = (props) => {
  return (
    <FileField
      label={props.label}
      onChange={async ([file]) => {
        const dataUrl = file && (await fileToDataUrl(file));
        props.onChange(dataUrl);
      }}
      multiple={false}
      value={props.value ? [dataUrlToFile(props.value)] : []}
    />
  );
};

export default FileWidget;
