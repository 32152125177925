import React, { forwardRef, useMemo } from "react";

import { withTheme, FormProps as JsonSchemaFormProps } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import FileWidget from "./Widgets/FileWidget";
import ArrayFieldTemplate from "./ArrayFieldTemplate";
import { useGetRefs } from "graphql/tripTypeAction";

const JsonSchemaForm = withTheme(MaterialUITheme);

const muiTheme = createMuiTheme({
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
    },
  },
});
export interface SchemaFormProps<T = any> extends JsonSchemaFormProps<T> {
  showSubmit?: boolean;
}

const SchemaForm = forwardRef(
  (
    {
      showSubmit = false,
      widgets,
      children,
      showErrorList = false,
      ...rest
    }: SchemaFormProps,
    ref
  ) => {
    const {
      data: {
        pp_refs_js: [refs],
      },
      loading,
    } = useGetRefs();

    const schema = useMemo(
      () => ({
        ...rest.schema,
        refs: refs?.refs,
      }),
      [refs, rest.schema]
    );

    if (loading) {
      return null;
    }

    const customWidgets = {
      FileWidget: FileWidget,
    };

    return (
      <MuiThemeProvider theme={muiTheme}>
        <JsonSchemaForm
          ArrayFieldTemplate={ArrayFieldTemplate}
          widgets={Object.assign(customWidgets, widgets)}
          // @ts-ignore
          ref={ref}
          showErrorList={showErrorList}
          {...rest}
          schema={schema}
        >
          {showSubmit ? children : <></>}
        </JsonSchemaForm>
      </MuiThemeProvider>
    );
  }
);

export default SchemaForm;
