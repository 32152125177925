import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
  leftColumn: { flexGrow: 1 },
  rightColumn: {
    marginLeft: theme.spacing(2),
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  tripMap: {
    height: 380,
    width: 380,
    padding: 3
  },
  tripMermaid: {
    width: 380,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3
  },
  tripAction: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    "&:first-child": {
      marginLeft: "auto",
    },
    "&:not(:first-child)": {
      marginLeft: theme.spacing(1.25),
    },
  },
  tripActionIcon: {
    border: "0.5px solid #716EDE",
    borderRadius: 4,
    padding: 3,
  },
  tripActionIconButton: {
    color: "#716EDE",
  },
}));

export default styles;
