import { useMemo, useCallback } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { get } from "lodash";

export interface IVariables {
  limit: number;
  offset?: number;
  search?: string;
}

export const GET_ORGANIZATIONS = gql`
  query GET_ORGANIZATIONS($limit: Int, $offset: Int, $search: String) {
    organization(
      limit: $limit
      offset: $offset
      where: { org_name: { _ilike: $search } }
      order_by: { org_id: asc }
    ) {
      meta
      org_name
      org_type
      org_id
    }
    organization_aggregate(
      where: { org_name: { _ilike: $search } }
    ) {
      aggregate{
        count
      }
    }
  }
`;

const useGetOrganizations = ({ limit, offset = 0, search }: IVariables) => {
  const { data, loading, error, refetch } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      limit,
      offset,
      search: search ? `%${search}%` : undefined
    },
    fetchPolicy: "no-cache"
  });

  const loadData = useCallback(
    (offset: number, limit: number, search?: string) => {
      refetch({
        offset,
        limit,
        search: search ? `%${search}%` : undefined
      });
    },
    [refetch]
  );

  const returnValue = useMemo(() => {
    const organizations = get(data, "organization", []);
    const total_rows = get(data, "organization_aggregate.aggregate.count", 0);
    return {
      data: { organizations, total_rows },
      loadData,
      loading,
      error,
      refetch
    };
  }, [data, loadData, loading, error, refetch]);

  return returnValue;
};

export default useGetOrganizations;
