import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';

import { Table as TableCore } from '@periplus/ui-library';

interface TableProps {
  className?: string;
  classes?: { table?: string };
  rowData: any[];
  columnDefs: any[];
  itemsPerPageOptions?: number[];
  loading?: boolean;
  onToggle?: (args: { selecting: any[]; deselecting: any[] }) => void;
  onExpand?: (id?: string) => void;
  totalItems: number;
  loadMore?: (limit: number, offset: number, search?: string) => void;
  sortBy?: { key: string; direction: "ASC" | "DESC" }[];
  onChangeSort?: (sortBy: {
      key: string;
      direction: "ASC" | "DESC";
  }[]) => void;
  onChangeItemsPerPage: (newItemsPerPage: number) => void;
  isRowSelected?: (data: any) => boolean;
  isRowExpanded?: (data: any) => boolean;
  selectedCount?: number;
  tableConfig?: {
      initialState?: any;
      [key: string]: any;
  };
  onDoubleClick?: (data: any) => void;
  selectionType?: "multiple" | "single";
  getSelectionCellProps?: (args: object) => any;
  renderExpandRowView?: (row: any) => ReactNode;
  disableMultiSort?: boolean;
  search?: string;
  [key: string]: any;
}

const perPageGetData = 1;
let loadData = false;

const Table: FC<TableProps> = ({
  rowData,
  columnDefs,
  loading,
  onExpand,
  totalItems,
  loadMore,
  initialItemsPerPage = 6,
  onChangeItemsPerPage,
  search,
}) => {
  const [currentPage, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const pageCount = useMemo(() => Math.ceil(totalItems / itemsPerPage), [
    totalItems,
    itemsPerPage,
  ]);

  const loadPages = useCallback(
    (nextPage: number) => {
      if (loadMore && nextPage % perPageGetData === 0) {
        loadMore(
          nextPage * itemsPerPage,
          itemsPerPage * perPageGetData,
          search
        );
        loadData = true;
      }
    },
    [loadMore, itemsPerPage, search]
  );

  const handlePageChange = (page: number) => {
    const newPage = Math.max(page - 1, 0);
    loadPages(newPage);
    setPage(newPage);
    onExpand && onExpand();
  };

  const handleItemsPerPageChange = (items: number) => {
    if (items === itemsPerPage || items === rowData.length) {
      return;
    }

    if (onChangeItemsPerPage) {
      onChangeItemsPerPage(items);
    }

    if (loadMore) {
      loadMore(0 * items, items * perPageGetData, search);
    }
    setPage(0);
    setItemsPerPage(items);
    loadData = true;
  };

  useEffect(() => {
    const page = pageCount - 1;
    if (pageCount > 0 && currentPage > page) {
      loadPages(page);
      setPage(page);
    }
  }, [currentPage, loadPages, pageCount]);

  useEffect(() => {
    loadData = false;
    // eslint-disable-next-line
  }, [rowData]);

  useEffect(() => {
    if (loading && !loadData) {
      setPage(0);
    }
    loadData = false;
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (loadMore) loadMore(0, itemsPerPage, search);
    setPage(0);
    // eslint-disable-next-line
  }, [loadMore, search]);

  return (
    <TableCore
      page={pageCount === currentPage ? currentPage : currentPage + 1}
      rowData={rowData}
      columnDefs={columnDefs}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      onChangePage={handlePageChange}
      onChangeItemsPerPage={handleItemsPerPageChange}
      loading={loading}
      search={search}
    />
  );
};

export default Table;
