import { useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getSearchParams } from "utils/urls";
import { useDebounceCallback } from "@react-hook/debounce";

const useUrlSearchParams = <T = any>(
  initialParams?: T
): { urlSearchParams: T; setUrlSearchParams: (arg: Partial<T>) => void } => {
  const history = useHistory();
  const { search } = useLocation();

  const saveToHistory = useDebounceCallback((searchParams) => {
    const newSearch = Object.keys(searchParams).reduce((acc, key) => {
      if (
        searchParams[key] === null ||
        searchParams[key] === undefined ||
        searchParams[key] === ""
      ) {
        return acc;
      }

      const string =
        encodeURIComponent(key) + "=" + encodeURIComponent(searchParams[key]);
      return acc === "" ? string : `${acc}&${string}`;
    }, "");

    history.push({
      search: newSearch,
    });
  }, 100);

  const urlSearchParams = useMemo(
    () => {
      const searchParams = {
        ...initialParams,
        ...getSearchParams(search),
      } as T;
      saveToHistory(searchParams);
      return searchParams;
    },
    // eslint-disable-next-line
    [search]
  );

  const setUrlSearchParams = useCallback(
    (newParams: { [key: string]: any }) => {
      saveToHistory({ ...urlSearchParams, ...newParams });
    },
    [saveToHistory, urlSearchParams]
  );

  return { urlSearchParams, setUrlSearchParams };
};

export default useUrlSearchParams;
