import React, { FC, useEffect, useState } from "react";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import printJS from "print-js";
import { IconButton, Tooltip } from "@material-ui/core";
import { downloadBlob } from "utils/azureBlobStorageUtils";
import { useAuth } from "keycloak/context/AuthContext";

interface PrintButtonProps {
  url: string;
  className: string;
}

const PrintButton: FC<PrintButtonProps> = ({ url, className }) => {
  const { idToken } = useAuth();
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    downloadBlob(url, idToken).then((result) => {
      setFileUrl(result);
    });
  }, [url, idToken]);

  return (
    <Tooltip title="Print">
      <IconButton
        size="small"
        onClick={() => {
          printJS({
            printable: fileUrl,
          });
        }}
      >
        <PrintOutlinedIcon className={className} />
      </IconButton>
    </Tooltip>
  );
};

export default PrintButton;
