import { useQuery, OperationVariables } from "react-apollo";
import { GET_TRIP_TYPES } from "./queries";
import { ITripTypes } from "./types";

const useGetTripTypes = (
  variables: { trip_type?: string } = {},
  options?: OperationVariables
) => {
  const { data, ...rest } = useQuery<{ pp_trip_types: ITripTypes[] }>(
    GET_TRIP_TYPES,
    {
      variables,
      ...options,
    }
  );

  return { data: { trip_types: data?.pp_trip_types || [] }, ...rest };
};

export default useGetTripTypes;
