import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { ActionBar, ActionBarItem } from "@periplus/ui-library";
import { useSnackbar } from "notistack";

import SchemaFormPlayground from "./Components/SchemaFormPlayground";

import {
  useGetTripTypeActions,
  useUpdateTripTypeAction,
} from "graphql/tripTypeAction";
import { toJson } from "utils/json";
import { useHistory, useParams } from "react-router-dom";

const simple = {
  params_schema: {},
  ui_schema: {},
};

const EditActionSchema: FC = () => {
  const history = useHistory();
  const { action_name } = useParams<{ action_name: string }>();
  const [formState, setFormState] = useState<any>({
    params_schema: simple.params_schema,
    ui_schema: simple.ui_schema,
  });

  const { enqueueSnackbar } = useSnackbar();
  const updateTripTypeAction = useUpdateTripTypeAction();

  const {
    data: {
      trip_actions: [trip_action],
    },
    loading,
    updateQuery,
  } = useGetTripTypeActions({ action_name });

  useEffect(() => {
    if (trip_action) {
      setFormState({
        params_schema: trip_action?.params_schema,
        ui_schema: trip_action?.ui_schema,
      });
    }
  }, [trip_action]);

  const handleChange = useCallback((type, value) => {
    setFormState((prev: any) => ({
      ...prev,
      [type]: value,
    }));
  }, []);

  const handleSave = useCallback(() => {
    if (formState.params_schema && formState.ui_schema) {
      updateTripTypeAction({
        action_name,
        params_schema: formState.params_schema,
        ui_schema: formState.ui_schema,
      }).then(() => {
        updateQuery(({ trip_type_actions }) => {
          return {
            trip_type_actions: trip_type_actions.map((item) => ({
              ...item,
              params_schema: formState.params_schema,
              ui_schema: formState.ui_schema,
            })),
          };
        });
        enqueueSnackbar("Saved", { variant: "success" });
      });
    }
  }, [
    action_name,
    enqueueSnackbar,
    formState.params_schema,
    formState.ui_schema,
    updateQuery,
    updateTripTypeAction,
  ]);

  const schemaJson = useMemo(() => toJson(formState.params_schema), [
    formState.params_schema,
  ]);

  return (
    <>
      <ActionBar>
        <ActionBarItem>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={history.goBack}
          >
            Cancel
          </Button>
        </ActionBarItem>
        <ActionBarItem justify="flex-end">
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={
              schemaJson === "{}" ||
              (toJson(trip_action?.params_schema) ===
                toJson(formState.params_schema) &&
                toJson(trip_action?.ui_schema) === toJson(formState.ui_schema))
            }
            onClick={handleSave}
          >
            Save
          </Button>
        </ActionBarItem>
      </ActionBar>
      <SchemaFormPlayground
        loading={loading}
        values={formState}
        onChange={handleChange}
      />
    </>
  );
};

export default EditActionSchema;
