import React, { FC, ReactNode } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  makeStyles,
  Theme,
  Typography,
  AccordionProps,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  accordionSummary: {
    flexDirection: "column",
  },
  accordionTitle: {
    fontSize: "1.125rem",
    lineHeight: "1.25rem",
    color: theme.palette.grey1.main,
  },
  accordionSubTitle: {
    color: theme.palette.grey3.main,
  },
}));

interface ExpandablePanelProps extends AccordionProps {
  title?: string;
  subTitle?: string;
  actions?: ReactNode;
}

const ExpandablePanel: FC<ExpandablePanelProps> = ({
  title,
  subTitle,
  children,
  actions,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Accordion {...rest}>
      {(title || subTitle) && (
        <AccordionSummary classes={{ content: classes.accordionSummary }}>
          <Typography variant="h6" className={classes.accordionTitle}>
            {title}
          </Typography>
          <Typography variant="subtitle2" className={classes.accordionSubTitle}>
            {subTitle}
          </Typography>
        </AccordionSummary>
      )}
      <AccordionDetails>{children}</AccordionDetails>
      {actions && <AccordionActions>{actions}</AccordionActions>}
    </Accordion>
  );
};

export default ExpandablePanel;
