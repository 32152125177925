import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { UPSERT_TRIP_STATE_COORDS } from "./queries";
import useErrorHandling from "hooks/useErrorHandling";

const useCreateTripState = () => {
  const [upsert] = useMutation(UPSERT_TRIP_STATE_COORDS);
  const withErrorHandling = useErrorHandling();

  const upsertTripType = useCallback(
    async (tripStateCoords: {
      trip_type: string;
      trip_state: string;
      coords: string;
      notes?: string;
    }) => {
      withErrorHandling(upsert, {
        variables: {
          tripStateCoords,
        },
      });
    },
    [upsert, withErrorHandling]
  );

  return upsertTripType;
};

export default useCreateTripState;
