import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  makeStyles,
  Theme,
  MenuItem,
  Typography,
  Box,
} from "@material-ui/core";
import {
  ActionBar,
  ActionBarItem,
  TextField,
  Button,
} from "@periplus/ui-library";
import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { useGetTripType } from "graphql/tripType";

import TripConfig from "./TripConfig";
import { useHistory, useParams, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  pageSpaces: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  createButton: {
    minWidth: 130,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),

    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  filterTitle: {
    fontSize: 14,
    color: theme.palette.grey4.main,
    "&:not(:first-child)": {
      marginLeft: 10,
    },
  },
  statusFilter: {
    width: 180,
    marginLeft: 10,
  },
  bottomSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

const TripConfigContainer: FC<{ create?: boolean }> = ({ create = false }) => {
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tripName, setTripName] = useState(tripTypeName);

  const {
    data: { trip_types },
    loading: tripTypesLoading,
    refetch,
  } = useGetTripType();

  const filteredTripTypes = useMemo(
    () =>
      trip_types
        .filter(
          (el) =>
            el.trip_type === "touton_coffee" || el.trip_type === "minerals"
        )
        .reverse(),
    [trip_types]
  ); //TODO Remove after DEMO

  useEffect(() => {
    if (!tripTypesLoading && !create && !tripTypeName) {
      const tripName = filteredTripTypes?.[0]?.trip_type;
      history.push(`/settings/tripType/edit/${tripName}`);
      setTripName(tripName);
    }
    if (create) {
      setTripName("");
    }
  }, [create, history, tripTypeName, tripTypesLoading, filteredTripTypes]);

  const handleFilterChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setTripName(value);
    history.push(`/settings/tripType/edit/${value}`);
  };

  const handleSubmit = useCallback(
    (tripTypeName?: string) => {
      setTimeout(() => {
        if (tripTypeName) {
          history.push(`/settings/tripType/edit/${tripTypeName}`);
          refetch();
        }
        enqueueSnackbar("Data successfully saved", { variant: "success" });
      }, 500);
    },
    [enqueueSnackbar, history, refetch]
  );

  const handleCreateNewTripType = () => {
    history.push(`/settings/tripType/create`);
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.pageSpaces}>
      <ActionBar className={classes.bottomSpacing}>
        <ActionBarItem container alignItems="center">
          <Typography className={classes.filterTitle}>
            {t("tripType")}:
          </Typography>
          <TextField
            variant="outlined"
            size="lg"
            select
            className={classes.statusFilter}
            value={tripName}
            onChange={handleFilterChange}
            SelectProps={{
              MenuProps: {
                variant: "menu",
              },
            }}
            disabled={tripTypesLoading}
          >
            {filteredTripTypes.map((trip_type) => (
              <MenuItem value={trip_type.trip_type} key={trip_type.trip_type}>
                {capitalize(trip_type.trip_type)}
              </MenuItem>
            ))}
          </TextField>
        </ActionBarItem>
        <ActionBarItem>
          <Button
            size="md"
            variant="contained"
            color="primary"
            onClick={() => history.push(`${pathname}/schema`)}
            className={classes.createButton}
          >
            Edit Trip Schema
          </Button>
          <Button
            size="md"
            variant="contained"
            color="primary"
            onClick={handleCreateNewTripType}
            className={classes.createButton}
          >
            Create Trip Type
          </Button>
        </ActionBarItem>
      </ActionBar>
      <TripConfig onSubmit={handleSubmit} />
    </Box>
  );
};

export default TripConfigContainer;
