import React, { FC, useCallback } from "react";
import { Box, capitalize, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { useGetTripTypeConfiguration } from "graphql/tripTypeConfigurations";

import TripType from "./components/TripTypePage";
import TripState from "./components/TripStatePage";
import TripActions from "./components/TripActionsPage";
import { useParams } from "react-router-dom";
import Mermaid from "components/Mermaid";

interface ITripConfigProps {
  onSubmit?: (tripTypeName?: string) => void;
}

const TripConfig: FC<ITripConfigProps> = ({ onSubmit }) => {
  const { tripTypeName = "" } = useParams<{ tripTypeName: string }>();

  const { data, loading, refetch } = useGetTripTypeConfiguration(
    { trip_type: tripTypeName },
    { skip: !tripTypeName }
  );

  const handleSubmit = useCallback(
    (tripTypename?: string) => {
      onSubmit?.(tripTypename);
      setTimeout(refetch, 1000);
    },
    [onSubmit, refetch]
  );

  const diagram = data.trip_types?.[0]?.state_diagram;

  return (
    <Box position="relative">
      <Grid container direction="row" spacing={2}>
        <Grid item md={9}>
          {loading ? (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Skeleton variant="rect" height={240} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" height={240} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" height={240} />
              </Grid>
            </Grid>
          ) : (
            <>
              <TripType
                onSubmit={handleSubmit}
                trip_type={data.trip_types[0]}
                trip_states={data.trip_type_states}
              />
              <TripState
                onSubmit={handleSubmit}
                trip_states={data.trip_type_states}
              />
              <TripActions
                onSubmit={handleSubmit}
                trip_actions={data.trip_type_actions}
                trip_states={data.trip_type_states}
              />
            </>
          )}
        </Grid>
        <Grid item md={3}>
          {data.trip_types?.[0]?.state_diagram && (
            <Box textAlign="center">
              <Typography variant="h6">
                {capitalize(tripTypeName || "")} diagram
              </Typography>
              <Mermaid chart={diagram} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TripConfig;
