import React, { FC } from "react";
import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 7,
    minWidth: "auto",
  },
  label: {
    width: "auto",
  },
}));

const SquareIconButton: FC<ButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.button, className)}
      classes={{ label: classes.label, ...rest.classes }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SquareIconButton;
