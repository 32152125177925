import gql from "graphql-tag";

export const CREATE_ORGANIZATION = gql`
  mutation CREATE_ORGANIZATION(
    $meta: jsonb
    $org_type: String!
    $org_name: String!
  ) {
    insert_organization(
      objects: { meta: $meta, org_name: $org_name, org_type: $org_type }
    ) {
      returning {
        org_id
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UPDATE_ORGANIZATION(
    $org_id: Int!
    $meta: jsonb
    $org_type: String!
    $org_name: String!
  ) {
    update_organization(
      where: { org_id: { _eq: $org_id } }
      _set: { meta: $meta, org_name: $org_name, org_type: $org_type }
    ) {
      returning {
        org_id
      }
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DELETE_ORGANIZATION($org_id: Int!) {
    delete_organization(where: { org_id: { _eq: $org_id } }) {
      returning {
        org_id
      }
    }
  }
`;
