import { useMemo } from "react";
import { useQuery, OperationVariables } from "react-apollo";
import { GET_TRIP_TYPE } from "./queries";
import { ITripTypes } from "./types";

const useGetTripType = (
  variables: { trip_type?: string } = {},
  options: OperationVariables = {}
) => {
  const result = useQuery<{ trip_type: ITripTypes[] }>(GET_TRIP_TYPE, {
    variables,
    ...options,
  });

  return useMemo(
    () => ({
      ...result,
      data: { trip_types: result.data?.trip_type || [] },
    }),
    [result]
  );
};

export default useGetTripType;
