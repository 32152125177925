import React, { useState, useEffect, useContext, useCallback } from "react";
import Token from "./IToken";
import config from "config";
import { useKeycloak } from "@react-keycloak/web";
import { SasStore } from "utils/blockBlobClient";
import { GET_USER } from "graphql/user";
import client from "graphql/client";

export enum HasuraRole {
  ADMINISTRATOR = "administrator",
  EDITOR = "editor",
  MANAGER = "manager",
  VIEWER = "viewer",
}

export enum Role {
  FORWARDER = "Forwarder",
  SUPPLIER = "Supplier",
  TRADER = "Trader",
  ADMIN = "Admin",
}

const namespace = "https://hasura.io/jwt/claims";

const delay = async (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
/**
 * Properties from the IdToken + the custom defined properties (in the Auth0 Rules!)
 */
export interface User extends Token {
  userId: string;
  allowedOrganizations: string[];
  currentOrganization: string;
  defaultRole: HasuraRole;
  currentRole: HasuraRole;
  allowedRoles: HasuraRole[];
  company_id?: string;
  [key: string]: any;
}

interface OwnIdToken extends Token {
  [key: string]: any;
}

/**
 * Translates decoded Auth0-Id-token into a custom, flat user object
 * @param idToken
 */
function getUserFromIdToken(idToken: OwnIdToken): User {
  // Strip the namespace, since we want to have a flat user object
  const { [namespace]: customProperties, ...authProperties } = idToken;
  return {
    userId: customProperties["x-hasura-user-id"],
    allowedOrganizations: customProperties["x-hasura-allowed-organizations"],
    currentOrganization: customProperties["x-hasura-current-organization"],
    defaultRole: customProperties["x-hasura-default-role"],
    currentRole: customProperties["x-hasura-current-role"],
    allowedRoles: customProperties["x-hasura-allowed-roles"],
    tenantId: customProperties["x-hasura-tenant-id"],
    ...authProperties,
  };
}

const updateSasTokens = () => {
  const sasStore = new SasStore();
  sasStore.updateSASForContainer(config.blobStorage.commodity);
  sasStore.updateSASForContainer(
    config.blobStorage.commodity,
    "application/pdf"
  );
  sasStore.updateSASForContainer(config.blobStorage.thumbsContainerName);
};

export interface IAuthContext {
  isAuthenticated: boolean;
  user: User | undefined;
  idToken: string | undefined;
  loading: boolean;
  loginWithRedirect: CallableFunction;
  logout: CallableFunction;
  refresh: CallableFunction;
}

const initialAuthContext: IAuthContext = {
  isAuthenticated: false,
  user: undefined,
  idToken: undefined,
  loading: true,
  loginWithRedirect: () => console.info("Initializing..."),
  logout: () => console.info("Initializing..."),
  refresh: () => console.info("Initializing..."),
};

// /**
//  * A function that routes the user to the right place after login
//  * @param appState
//  */
// export const onRedirectCallback = (appState: any) => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

export const AuthContext = React.createContext<IAuthContext>(
  initialAuthContext
);
export const useAuth = () => useContext(AuthContext);

// TODO(df): Improve typing...
export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>();
  const [idToken, setIdToken] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const { keycloak, initialized } = useKeycloak();

  //@ts-ignore
  keycloak.onReady = useCallback(() => {}, []);

  //@ts-ignore
  keycloak.onAuthSuccess = () => {
    if (!keycloak) return null;
    const user = keycloak.idTokenParsed || {};

    setUser(getUserFromIdToken(user));
    updateSasTokens();
  };

  //@ts-ignore
  keycloak.onTokenExpired = () => {
    //@ts-ignore
    keycloak.updateToken(25);
    updateSasTokens();
  };

  //@ts-ignore
  keycloak.onAuthRefreshError = () => {
    console.log("onAuthRefreshError");
    setAuthenticated(false);
    //@ts-ignore
    keycloak.login({
      redirectUri: config.keycloak.redirectUrl,
    });
  };

  const getDBUser = useCallback(async (userId: any) => {
    const {
      data: { users },
    } = await client.query({
      query: GET_USER,
      variables: {
        id: userId,
      },
      fetchPolicy: "no-cache",
    });
    //@ts-ignore
    return users[0];
  }, []);

  let initAuthClient = useCallback(async () => {
    while (!initialized) {
      await delay(10);
    }

    //@ts-ignore
    if (keycloak.authenticated || false) {
      //@ts-ignore
      const user = keycloak.idTokenParsed || {};
      const userFromIdToken = getUserFromIdToken(user);
      const dbUser = await getDBUser(userFromIdToken.userId);
      setUser({ ...userFromIdToken, ...dbUser });
      setAuthenticated(true);
      setIdToken(keycloak?.idToken);
      updateSasTokens();
    }

    setLoading(false);
  }, [initialized, getDBUser, keycloak]);

  if (config.isProdMode === true) {
    initAuthClient = async () => Promise.resolve();
  }

  useEffect(() => {
    initAuthClient();
  }, [initAuthClient]);

  if (config.isProdMode === true) {
    //todo add NODE_ENV check
    return (
      <AuthContext.Provider
        value={{
          ...initialAuthContext,
          isAuthenticated: true,
          loading: false,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        user,
        idToken,
        loading,
        loginWithRedirect: () => {
          //@ts-ignore
          keycloak.login({
            redirectUri: config.keycloak.redirectUrl,
          });
        },
        //@ts-ignore
        logout: () => keycloak.logout(),
        refresh: async () => {
          if (!user) return;
          const dbUser = await getDBUser(user.userId);
          setUser(
            (prev) =>
              prev && {
                ...prev,
                company_id: dbUser?.company_id,
              }
          );
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
