import { createMuiTheme } from "@material-ui/core";
import RubikLight from "fonts/Rubik-Light.woff2";
import RubikRegular from "fonts/Rubik-Regular.woff2";
import RubikMedium from "fonts/Rubik-Medium.woff2";

export type Sizes = "xs" | "sm" | "md" | "lg" | "xl";
export type Colors =
  | "primary"
  | "default"
  | "secondary"
  | "grey3"
  | "grey4"
  | "grey2"
  | "purple";

interface Size {
  height: number;
  minHeight: number;
  fontSize: number;
  fontWeight: number;
  iconFontSize: number;
}

interface SizeOptions {
  xs: Size;
  sm: Size;
  md: Size;
  lg: Size;
  xl: Size;
}

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    default: SimplePaletteColorOptions;
    grey1: SimplePaletteColorOptions;
    grey2: SimplePaletteColorOptions;
    grey3: SimplePaletteColorOptions;
    grey4: SimplePaletteColorOptions;
    grey5: SimplePaletteColorOptions;
    purple: SimplePaletteColorOptions;
    grey6: SimplePaletteColorOptions;
    green3: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
  }
  interface Palette {
    default: SimplePaletteColorOptions;
    grey1: SimplePaletteColorOptions;
    grey2: SimplePaletteColorOptions;
    grey3: SimplePaletteColorOptions;
    grey4: SimplePaletteColorOptions;
    grey5: SimplePaletteColorOptions;
    purple: SimplePaletteColorOptions;
    grey6: SimplePaletteColorOptions;
    green3: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
  }
}

const rubikGeneral = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontDisplay: "swap",
};

const rubikLight = {
  ...rubikGeneral,
  fontWeight: 300,
  src: `
    local('Rubik'),
    local('Rubik-Light'),
    url(${RubikLight}) format('woff2')
  `,
};

const rubik = {
  ...rubikGeneral,
  fontWeight: 400,
  src: `
    local('Rubik'),
    local('Rubik-Regular'),
    url(${RubikRegular}) format('woff2')
  `,
};

const rubikBold = {
  ...rubikGeneral,
  fontWeight: 600,
  src: `
    local('Rubik'),
    local('Rubik-Medium'),
    url(${RubikMedium}) format('woff2')
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: "Rubik",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // @ts-ignore
        "@font-face": [rubikLight, rubik, rubikBold],
      },
    },
  },
  palette: {
    primary: {
      light: "rgba(199, 214, 222, 0.22)",
      main: "#221ECD",
      dark: "rgba(100, 161, 194, 0.32)",
      contrastText: "#fff",
    },
    default: {
      main: "grey",
      contrastText: "#fff",
    },

    error: {
      main: "#D73333",
      dark: "#BE2525",
    },
    grey1: {
      main: "#333333",
    },
    grey2: {
      main: "#4F4F4F",
    },
    grey3: {
      main: "#828282",
    },
    grey4: {
      main: "#BDBDBD",
    },
    grey5: {
      main: "#C8C8D4",
    },
    grey6: {
      main: "#F2F2F2",
    },
    green3: {
      main: "#32D376",
    },
    purple: {
      main: "#716EDE",
    },
    white: {
      main: "#FFFFFF",
    },
  },
});

export default theme;
