import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& > p": {
      position: "absolute",
      top: "95%",
      margin: 0,
    },
  },
}));

interface IFieldProps {
  classes?: {
    root?: string;
    input?: string;
  };
  error?: boolean;
  helperText?: string;
  onChange?: (e: ChangeEvent<any>, value: any) => void;
  value?: any;
  label: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  variant?: "input" | "select" | "autocomplete";
  multiline?: boolean;
  autoCompleteConfigs?: Partial<
    AutocompleteProps<any, boolean, boolean, boolean>
  >;
  selectConfigs?: Partial<TextFieldProps>;
  inputConfigs?: Partial<TextFieldProps>;
  options?: any[];
}

const Field: FC<IFieldProps> = ({
  classes = {},
  error,
  helperText,
  onChange,
  value,
  label,
  disabled,
  required,
  name = "",
  variant = "input",
  multiline,
  options,
  autoCompleteConfigs = {},
  selectConfigs = {},
  inputConfigs = {},
}) => {
  const innerClasses = useStyles();
  const { root, input } = classes;

  const handleChange = useCallback(
    (e: ChangeEvent<any>, value?: any) => {
      e.persist();
      if (onChange) {
        onChange(e, value || e.target.value);
      }
    },
    [onChange]
  );

  const defaultInputValues = useMemo(
    () => ({
      name,
      className: clsx(input, innerClasses.input),
      value,
      disabled,
      onChange: handleChange,
    }),
    [disabled, handleChange, innerClasses.input, input, name, value]
  );

  const Element = useMemo(
    () =>
      variant === "autocomplete" ? (
        <Autocomplete
          {...defaultInputValues}
          size="small"
          multiple={multiline}
          options={options || []}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder={value ? "" : label}
              name={name}
              size="small"
              multiline={multiline}
              variant="outlined"
              helperText={helperText}
              error={error}
            />
          )}
          {...autoCompleteConfigs}
        />
      ) : (
        <TextField
          {...defaultInputValues}
          placeholder={label}
          select={variant === "select"}
          {...(variant === "select" ? { options: options || [] } : {})}
          multiline={multiline}
          size="small"
          variant="outlined"
          helperText={helperText}
          error={error}
          {...selectConfigs}
          {...inputConfigs}
        />
      ),
    [
      autoCompleteConfigs,
      defaultInputValues,
      error,
      helperText,
      inputConfigs,
      label,
      multiline,
      name,
      options,
      selectConfigs,
      value,
      variant,
    ]
  );

  return (
    <FormControl className={root} error={error}>
      <InputLabel required={required} shrink htmlFor={name}>
        {label}
      </InputLabel>
      {Element}
    </FormControl>
  );
};

export default Field;
