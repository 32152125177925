import gql from "graphql-tag";

export const GET_TRIP_COORDS = gql`
  query GET_TRIP_COORDS($tripType: String, $tripState: String) {
    trip_coords(
      where: {
        _and: { trip_type: { _eq: $tripType }, trip_state: { _eq: $tripState } }
      }
    ) {
      coords
      created
      notes
      state_description
      trip_id
      trip_state
      trip_type
    }
  }
`;

export const GET_TRIPS = gql`
  query GET_TRIPS(
    $limit: Int
    $offset: Int
    $date_min: timestamptz
    $date_max: timestamptz
    $trip_type: String
    $state: _text = "{}"
    $search: String = ""
    $orderby: String
    $desc: Boolean
    $trip_id: bigint
  ) {
    pp_trips(
      args: {
        _trip_type: $trip_type
        _offset: $offset
        _limit: $limit
        _maxdate: $date_max
        _mindate: $date_min
        _states: $state
        _search: $search
        _orderby: $orderby
        _desc: $desc
        _trip_id: $trip_id
      }
    ) {
      qty
      trip_id
      trip_type
      created
      trip_data
      state
      state_description
      state_meta
      available_actions
      edit_action
      cargo_position
      tripDocs {
        action_name
        param_name
        url
        pages_qty
      }
      tripType {
        state_diagram
      }
      tripTypeState {
        display_name
      }
      tripCoordsHistory(order_by: { created: asc }) {
        coords
      }
      tripActions(order_by: { created: asc }) {
        created
        action_params
        tripTypeState {
          display_name
          tripTypeStateCoord {
            coords
          }
        }
        tripTypeAction {
          params_schema
        }
        tripActionDocuments {
          url
          pages_qty
          param_name
        }
      }
    }
  }
`;

export const INSERT_TRIP_ACTION = gql`
  mutation INSERT_TRIP_ACTION(
    $tripId: bigint!
    $action: String!
    $params: jsonb!
  ) {
    insert_trip_action_one(
      object: { trip_id: $tripId, action_name: $action, action_params: $params }
    ) {
      trip_id
      action_name
      action_params
      created
    }
  }
`;

export const START_TRIP = gql`
  mutation START_TRIP($tripType: String) {
    insert_trip_one(object: { trip_type: $tripType }) {
      trip_id
    }
  }
`;

export const UPDATE_TRIP_ACTION = gql`
  mutation UPDATE_TRIP_ACTION(
    $trip_id: bigint!
    $action_name: String!
    $action_params: jsonb!
  ) {
    update_trip_action(
      where: { trip_id: { _eq: $trip_id }, action_name: { _eq: $action_name } }
      _set: { action_params: $action_params }
    ) {
      returning {
        trip_id
      }
    }
  }
`;

export const DELETE_TRIP_ACTION = gql`
  mutation DELETE_TRIP_ACTION($trip_id: bigint!, $action_name: String!) {
    delete_trip_action(
      where: { trip_id: { _eq: $trip_id }, action_name: { _eq: $action_name } }
    ) {
      returning {
        trip_id
      }
    }
  }
`;
