import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable, split } from "apollo-link";
import { ErrorResponse, onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { withClientState } from "apollo-link-state";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import config from "config";
import Keycloak from "keycloak-js";
import { KeycloakInstance } from "keycloak-js";

export const keycloak: KeycloakInstance = Keycloak({
  url: config.keycloak.url,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
  // onLoad: "login-required",
  // flow: "implicit",
  // responseType: "id_token token"
});

/**
 * Cache. Could set redirects etc
 */
const cache = new InMemoryCache();

/**
 * Handles GraphQL error. Note that you can not use async/await... https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
 * @param {} graphQLErrors
 */
function handleGraphQLError() {
  refreshToken(25);
}

/**
 * Handles Network errors such as token expired, invalid etc...
 * @param {} networkError
 */
function handleNetworkError() {
  refreshToken(25);
}

/**
 * Set token on each request
 */
const request = (operation: any) => {
  operation.setContext({
    headers: {
      //@ts-ignore
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation: any, forward: any) =>
    new Observable((observer: any) => {
      let handle: any;
      Promise.resolve(operation)
        .then(request)
        .then(() => {
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: console.log,
            next: observer.next.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);

function getWsUri(uri = config.graphQl.url) {
  if (uri.includes("https://")) {
    return uri.replace("https://", "wss://");
  }
  return uri.replace("http://", "ws://");
}

// Create an http link:
const httpLink = new HttpLink({
  uri: config.graphQl.url,
});

function refreshToken(minValidity: number) {
  return new Promise((resolve, reject) => {
    keycloak
      .updateToken(minValidity)
      //@ts-ignore
      .success(resolve)
      //@ts-ignore
      .error(reject);
  });
}

async function getAsyncConnectionParams() {
  await refreshToken(25);
  return {
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  };
}

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  options: {
    connectionParams: getAsyncConnectionParams,
    lazy: true,
    reconnect: true,
    timeout: 30000,
  },
  uri: getWsUri(),
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }: any) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

/**
 * Setup apollo-link-state, the state management (similar to redux...).
 */
const stateLink = withClientState({
  cache,
  resolvers: {},
});

/**
 * Initialize Apollo Client
 * TODO: Change errorPolicy for production
 */
const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError((e: ErrorResponse) => {
      if (e.graphQLErrors) {
        handleGraphQLError();
      }
      if (e.networkError) {
        handleNetworkError();
      }
      if (!e.graphQLErrors && !e.networkError) {
        console.log("error in ApolloClient");
        throw e;
      }
    }),
    requestLink,
    stateLink,
    link,
  ]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
});

// Register onReset function
// @ts-ignore
client.onResetStore(stateLink.writeDefaults);

export default client;
