import gql from "graphql-tag";

export const GET_TRIP_TYPE_ACTIONS = gql`
  query GET_TRIP_TYPE_ACTIONS($trip_type: String, $action_name: String) {
    trip_type_actions(
      where: {
        trip_type: { _eq: $trip_type }
        action_name: { _eq: $action_name }
      }
    ) {
      action_name
      display_name
      params_schema
      ui_schema
    }
  }
`;

export const CREATE_TRIP_TYPE_ACTION = gql`
  mutation CREATE_TRIP_TYPE_ACTION(
    $tripType: [trip_type_action_insert_input!]!
  ) {
    insert_trip_type_action(
      objects: $tripType
      on_conflict: {
        constraint: pk_trip_type_action_action_name
        update_columns: action_name
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_TRIP_TYPE_ACTION = gql`
  mutation UPDATE_TRIP_TYPE_ACTION(
    $action_name: String!
    $params_schema: json!
    $ui_schema: json!
  ) {
    update_trip_type_action(
      where: { action_name: { _eq: $action_name } }
      _set: { params_schema: $params_schema, ui_schema: $ui_schema }
    ) {
      affected_rows
    }
  }
`;

export const GET_REFS = gql`
  query GET_REFS {
    pp_refs_js {
      refs(path: "refs")
    }
  }
`;
